import { createContext, useContext, useState } from "react";

type LoadingProviderProps = { children: React.ReactNode }

const LoadingContext = createContext<{ loading: boolean, setLoading: ((value: boolean) => void) }>({
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLoading: (value: boolean) => { null }
});

export function LoadingProvider(props: LoadingProviderProps) {
  const [loading, setLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {props.children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within provider");
  }
  return context;
}
