import { FormEvent, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import './text-area.scss';

export type TextAreaProps = {
  // shared
  id: string,
  name: string;
  label: string;
  // native
  value?: string | number;
  onChange?: (event: FormEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  rows?: number;
  maxLength?: number; // Add maxLength
  // react-hook-form required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
}

const TextArea: FunctionComponent<TextAreaProps> = (props) => {
  const defaultRows = 5; // Adjust this value according to your desired minimum height

  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({ field, fieldState }) => {
          return (
            <div className="text-area">
              <label htmlFor={props.id} className="text-area__label">{props.label}</label>
              <textarea
                id={props.id}
                placeholder={props.placeholder}
                disabled={!!props.disabled}
                required={props.required}
                rows={props.rows || defaultRows}
                maxLength={props.maxLength || undefined}
                className={`text-area__input ${fieldState.error ? ' text-area__input--error' : ''}`}
                value={field.value}
                onChange={field.onChange}
              />
              <p className="text-area__error-message">{fieldState?.error?.message}</p>
            </div>
          );
        }}
      />
    );
  }
  else {
    return (
      <div className="text-area">
        <label htmlFor={props.id}>{props.label}</label>
        <textarea
          id={props.id}
          placeholder={props.placeholder}
          disabled={!!props.disabled}
          required={props.required}
          rows={props.rows || defaultRows}
          maxLength={props.maxLength || undefined} 
          className={`text-area__input`}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    );
  }
}

export default TextArea;
