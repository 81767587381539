import { FunctionComponent } from "react";
import { Button, ButtonType } from "_components";
import './conflicted-options-modal-content.scss';
import { useTranslation } from "react-i18next";


type ConflictedOptionsModalContentProps = {
  onClose: () => void;
}

type ConflictedOptionsType = {
  lastAdded: string 
  added: string[] | null,
  deleted: string[] | null,
  toChoose: string[] | null
}

const options:ConflictedOptionsType = {
  lastAdded: "Limitateur de vitesse intelligent",
  added: [
    "jantes en or",
    "toit ouvrant"
  ],
  deleted : [
    "jantes 19",
    "peinture mate"
  ],
  toChoose: [
    "volant avec moumoute",
    "désodorisant sapin",
    "pilote automatique"
  ]
}

function handleSubmit() {
  console.log(options);
}

const ConflictedOptionsModalContent: FunctionComponent<ConflictedOptionsModalContentProps> = ({ onClose }) => {

  const { t } = useTranslation();
  
  return (
    <div className="conflicted-options-modal-content">
      <div className="conflicted-options-modal-content__title">{t('configuration_help')}</div>
        <form className="conflicted-options-modal-content__form" onSubmit={handleSubmit}>

          <div className="conflicted-options-modal-content__conflicted-option">
            <div className="conflicted-options-modal-content__notice">{t('configuration_modification')}</div>
            <div className="conflicted-options-modal-content__option-added">{t('option_added')}</div>
            <input className="conflicted-options-modal-content__option-name" value={options.lastAdded} readOnly/>
          </div>
          
          <div className="conflicted-options-modal-content__outcomes">
            <div className="conflicted-options-modal-content__outcome">
            <label className="conflicted-options-modal-content__label">{t('added_elements')} :</label>
              {options.added?.map((option, index) =>(
                <input className="conflicted-options-modal-content__option" key={index} value={option} readOnly/>
              ))}
            </div>
            <div className="conflicted-options-modal-content__outcome">
            <label className="conflicted-options-modal-content__label">{t('deleted_elements')}:</label>
              {options.deleted?.map((option, index) =>(
                <input className="conflicted-options-modal-content__option" key={index} value={option} readOnly/>
              ))}
            </div>
            <div className="conflicted-options-modal-content__outcome">
            <label className="conflicted-options-modal-content__label">{t('to_choose_elements')}:</label>
              {options.toChoose?.map((option, index) =>(
                <input className="conflicted-options-modal-content__option" key={index} value={option} readOnly/>
              ))}
            </div>
          </div>

          <div className="conflicted-options-modal-content__buttons">
            <Button type={ButtonType.PRIMARY} text="Ajouter" onClick={onClose} />
            <Button type={ButtonType.TERTIARY} text="Annuler" onClick={onClose} />
          </div>

        </form>

    </div>
  );
}

export default ConflictedOptionsModalContent;