import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { formatPrice } from "_utils";
import './horizontal-option-value.scss';

export type OptionValueType = {
  _id?: number,
  id: string,
  label: string,
  price: number,
  value: string,
  disabled?: boolean,
  picture: string | null
}

export type HorizontalOptionValueProps = {
  // Shared
  options: Array<OptionValueType>,
  name: string,
  label: string,
  // Native
  value?: OptionValueType,
  picture?: string | null,
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
  // react-hook-form required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
}

const HorizontalOptionValue: FunctionComponent<HorizontalOptionValueProps> = (props) => {

  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => {

          // const [value, setValue] = useState<OptionValueType>(field.value)

          const onButtonClick = (option: OptionValueType) => {
            //setValue(option)
            field.onChange(option);
          }

          /*
          useEffect(() => {
            field.onChange(value)
          }, [value])
          */

          return (
            <div className="horizontal-option-value" {...props}>
              <div className="horizontal-option-value__title">{props.label}</div>
              <div className="horizontal-option-value__item">
                {props.options.map((option: OptionValueType) => {
                  const priceValue = option.price ? `(+ ${formatPrice(option.price)})` : `(${formatPrice(0)})`;

                  if (option.picture !== undefined) {

                    return (
                      <button key={option.id} className={`horizontal-finition-option-value__button ${field.value && option.value === field.value.value ? 'horizontal-finition-option-value__button--active' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          onButtonClick(option);
                        }}
                        disabled={option.disabled}
                      >
                        <div className="horizontal-finition-option-value__image" style={{ backgroundImage: `url("${option.picture}")` }}></div>
                        <div className="horizontal-finition-option-value__content">
                          <div className="horizontal-finition-option-value__text">
                            <div className="horizontal-finition-option-value__label">{option.label}</div>
                            <div className="horizontal-finition-option-value__sublabel">{priceValue}</div>
                          </div>
                        </div>
                      </button>
                    )

                  } else {


                    return (
                      <button key={option.id} className={`horizontal-option-value__button ${field.value && option.value === field.value.value ? 'horizontal-option-value__button--active' : ''}`}
                        onClick={() => onButtonClick(option)}
                        disabled={option.disabled}
                      >
                        <div className="horizontal-option-value__content">
                          <div className="horizontal-option-value__text">
                            <span className="horizontal-option-value__label">{option.label === "" ? option.value : option.label}</span>
                            <span className="horizontal-option-value__sublabel">{priceValue}</span>
                          </div>
                        </div>
                      </button>
                    )


                  }
                })}
              </div>
            </div >
          );
        }}
      />
    );
  } else {
    return (
      /*
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => {

          const [value, setValue] = useState<OptionValueType>(field.value)

          const onButtonClick = (option: OptionValueType) => {
            setValue(option)
          }

          useEffect(() => {
            field.onChange(value)
          }, [value])
          return (
            <div className="horizontal-option-value" {...props}>
              <div className="horizontal-option-value__title">{props.label}</div>
              <div className="horizontal-option-value__item">
                {props.options.map((option: OptionValueType) => {
                  const priceValue = option.price ? `(+ ${formatPrice(option.price)})` : `(${formatPrice(0)})`;
                  return (
                    <button key={option.id} className={`horizontal-option-value__button ${value && option.value === value.value ? 'horizontal-option-value__button--active' : ''}`}
                      onClick={() => onButtonClick(option)}
                      disabled={option.disabled}
                    >
                      <div className="horizontal-option-value__content">
                        <div className="horizontal-option-value__text">
                          <span className="horizontal-option-value__label">{option.label}</span>
                          <span className="horizontal-option-value__sublabel">{priceValue}</span>
                        </div>
                      </div>
                    </button>
                  )
                })}
              </div>
            </div>
          );
        }}
      />
      */
      null
    );
  }
}

export default HorizontalOptionValue;