import { FunctionComponent } from "react";
import { createPortal } from "react-dom";
import './configurator-zoom-photo-viewer.scss';

type ConfiguratorZoomPhotoViewerProps = {
  picture: string;
  zoomed: boolean;
  onClose: () => void;
}

const ConfiguratorZoomPhotoViewerContent: FunctionComponent<ConfiguratorZoomPhotoViewerProps> = ({ picture, zoomed, onClose }) => {
  return (
    <div className={`configurator-zoom-photo-viewer ${zoomed ? 'configurator-zoom-photo-viewer--visible' : ''}`}>
      <div className="configurator-zoom-photo-viewer__background" onClick={onClose}></div>
      <div className="configurator-zoom-photo-viewer__img-wrapper">
        <button className="configurator-zoom-photo-viewer__close-button" onClick={onClose}></button>
        <img className="configurator-zoom-photo-viewer__img" src={picture} alt="" />
      </div>
    </div>
  );
}

const ConfiguratorZoomPhotoViewer: FunctionComponent<ConfiguratorZoomPhotoViewerProps> = ({ picture, zoomed, onClose }) => {
  return createPortal(
    <ConfiguratorZoomPhotoViewerContent picture={picture} zoomed={zoomed} onClose={onClose} />,
    document.body
  );
}

export default ConfiguratorZoomPhotoViewer;
