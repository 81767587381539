import { AxiosResponse } from "axios";
import {
  AuthenticatedClient,
  ConfiguratorResponseType,
  PTACResponseType,
} from "_api";
import { ConfigurationIds } from "_types";

export const getConfig = (
  configIds: ConfigurationIds,
  lang: string | undefined
): Promise<AxiosResponse<ConfiguratorResponseType>> => {
  return AuthenticatedClient.post("/configurations", {
    ATNR: configIds.atnr,
    CUNO: configIds.cuno,
    FACI: configIds.faci,
    WHLO: configIds.whlo,
    ITNO: configIds.itno,
    vehicle_id: configIds.vehicle_id,
    DANR: configIds.danr,
    OREF: configIds.oref,
    ORNO: configIds.orno,
    dealer: configIds.dealer,
    availability_id: configIds.availability_id,
    newATNR: configIds.newAtnr,
    newORNO: configIds.newOrno,
    clientMail: configIds.clientMail,
    dealerMail: configIds.dealerMail,
    initialATNR: configIds.initialATNR,
    initialORNO: configIds.initialORNO,
    dealerslist: configIds.dealerslist,
    millesime: configIds.millesime,
    slot: configIds.slot,
    SCOM: configIds.scom,
    country_user: configIds.country,
    dead_line_production_date: configIds.dead_line_production_date,
    STAT: configIds.stat,
    STAS: configIds.stas,
    FIDT: configIds.fidt,
    dead_line_due_date: configIds.dead_line_due_date,
    lang: lang,
  });
};

export const updateConfigAPI = (
  atnr: string,
  configIds: ConfigurationIds,
  regularParams: any,
  clientInfosParams: any,
  distributorOptions: any,
  clientMail: string | null,
  dealerMail: string | null,
  lang: string | undefined
): any => {
  const params = {
    ATNR: atnr,
    CUNO: configIds.cuno,
    FACI: configIds.faci,
    WHLO: configIds.whlo,
    ITNO: configIds.itno,
    vehicle_id: configIds.vehicle_id,
    DANR: null,
    OREF: null,
    ORNO: configIds.orno,
    dealer: configIds.dealer,
    data: regularParams,
    clientInfos: clientInfosParams,
    distributorOptions: distributorOptions,
    clientMail: clientMail,
    dealerMail: dealerMail,
    newATNR: configIds.newAtnr,
    newORNO: configIds.newOrno,
    initialATNR: configIds.initialATNR,
    initialORNO: configIds.initialORNO,
    slot: configIds.slot,
    millesime: configIds.millesime,
    availability_id: configIds.availability_id,
    country_user: configIds.country,
    lang: lang,
    dealerslist: configIds.dealerslist,
  };
  return AuthenticatedClient.post(
    "/configurations/changeconfiguration",
    params
  );
};

export const updateConfigClient = (
  configIds: ConfigurationIds,
  clientInfosParams: any,
  lang: string | undefined
): any => {
  const params = {
    ATNR: configIds.atnr,
    CUNO: configIds.cuno,
    FACI: configIds.faci,
    WHLO: configIds.whlo,
    ITNO: configIds.itno,
    vehicle_id: configIds.vehicle_id,
    DANR: null,
    OREF: null,
    ORNO: configIds.orno,
    dealer: configIds.dealer,
    clientInfos: clientInfosParams,
    newATNR: configIds.newAtnr,
    newORNO: configIds.newOrno,
    initialATNR: configIds.initialATNR,
    initialORNO: configIds.initialORNO,
    slot: configIds.slot,
    millesime: configIds.millesime,
    availability_id: configIds.availability_id,
    country_user: configIds.country,
    lang: lang,
    dealerslist: configIds.dealerslist,
  };
  return AuthenticatedClient.post(
    "/configurations/changeconfiguration",
    params
  );
};

export const getPTACOptions = (
  configIds: ConfigurationIds
): Promise<AxiosResponse<PTACResponseType>> => {
  const itno = configIds.itno;
  const whlo = configIds.whlo;
  const atnr = configIds.atnr;
  const vehicle_id = configIds.vehicle_id;
  const country = configIds.country;
   return AuthenticatedClient.get(
    `/configurations/changeptac?WHLO=${encodeURI(whlo)}&ATNR=${encodeURI(
      atnr
    )}&ITNO=${encodeURI(itno)}&vehicle_id=${encodeURI(
      vehicle_id.toString()
    )}&country_user=${encodeURI(country)}`
  );
};

export const updateConfigPTAC = (
  atnr: string,
  configIds: ConfigurationIds,
  ATID: string,
  PTAC: string,
  id: string,
  lang?: string
): Promise<AxiosResponse<ConfiguratorResponseType>> => {
  const params = {
    ATNR: configIds.atnr,
    CUNO: configIds.cuno,
    FACI: configIds.faci,
    WHLO: configIds.whlo,
    ITNO: configIds.itno,
    vehicle_id: configIds.vehicle_id,
    DANR: configIds.danr,
    OREF: configIds.oref,
    ORNO: configIds.orno,
    dealer: configIds.dealer,
    availability_id: configIds.availability_id,
    newATNR: configIds.newAtnr,
    newORNO: configIds.newOrno,
    clientMail: configIds.clientMail,
    dealerMail: configIds.dealerMail,
    initialATNR: configIds.initialATNR,
    initialORNO: configIds.initialORNO,
    dealerslist: configIds.dealerslist,
    millesime: configIds.millesime,
    slot: configIds.slot,
    change_ptac: true,
    country_user: configIds.country,
    lang: lang,
    data: {
      id: id,
      values: PTAC,
      name: ATID,
    },
  };
  return AuthenticatedClient.post(
    "/configurations/changeconfiguration",
    params
  );
};

export const updateConfigCG = (
  atnr: string,
  configIds: ConfigurationIds,
  cg: number | null,
  lang?: string
): Promise<AxiosResponse<ConfiguratorResponseType>> => {
  const params = {
    ATNR: atnr,
    WHLO: configIds.whlo,
    ITNO: configIds.itno,
    vehicle_id: configIds.vehicle_id,
    slot: configIds.slot,
    millesime: configIds.millesime,
    availability_id: configIds.availability_id,
    value: cg !== null ? cg - 1 : null,
    lang: lang,
  };
  return AuthenticatedClient.post("/configurations/changecartagrisa", params);
};
