import { FunctionComponent, useEffect, useState } from "react";
import { Button, ButtonType, TextArea, TextInput } from "_components";
import { Control, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import './order-confirmation-modal-content.scss';
import { useTranslation } from "react-i18next";
import atomQuote from "_atoms/atomQuote";
import { useRecoilState, useRecoilValue } from "recoil";
import { ConfiguratorClientInfos, QuoteGenerationType, QuoteOrderResponseType, UserResponseType, getUser, quoteToOrder, updateConfigClient } from "_api";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useLoading } from "_hooks";
import atomConfigIds from "_atoms/atomConfigIds";
import { atomUser, atomUserLang } from "_atoms";


type OrderConfirmationModalContentProps = {
  onClose: () => void;
  control?: Control<TestFormValues>
}

type TestFormValues = {
  info: Commentary | null,
  name: string | null,
  firstname: string | null,
  email: string | null,
  phone: string | null,
  isChecked: boolean
}

type Dealer = {
  name: string | null,
  email: string | null,
  phone: string | null,
  address: string | null,
} | null;


type Commentary = {
  description: string | null,
} | null;



const OrderConfirmationModalContent: FunctionComponent<OrderConfirmationModalContentProps> = ({ onClose }) => {

  const user = useRecoilValue(atomUser);


  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const { t } = useTranslation();
  const [atomQuoteValue] = useRecoilState(atomQuote);
  const [dealer, setDealer] = useState<Dealer>(null);
  const { setLoading } = useLoading();
  const [configIds, setConfigIds] = useRecoilState(atomConfigIds);
  const [lang] = useRecoilState(atomUserLang);

  const [userData, setUserData] = useState<UserResponseType>();


  const isCommercial =  userData?.role.name === "Commercial";

  useEffect(() => {
    if(user){
    getUser(user.id).then((result) => {
      if (result && result.data && result.data.length) {
        setUserData(result.data[0]);
      }
    }, () => {
      toast.error(t('toast_get_data_error'));
    })
    }
  }, [user]);


  useEffect(() => {
    if (atomQuoteValue && atomQuoteValue.form && atomQuoteValue.form.clientInfos) {
      const dealer = (atomQuoteValue.form.clientInfos as ConfiguratorClientInfos).dealer;
      const client = (atomQuoteValue.form.clientInfos as ConfiguratorClientInfos).client;
      setDealer(dealer);
    }
  })

  const testValidationSchema = Yup.object().shape({
    info: Yup.string(),
    name: Yup.string(),
    firstname: Yup.string(),
    email: Yup.string().email('invalid format'),
    //phone: Yup.string().matches(phoneRegExp, t('invalid_format')).min(6),
    isChecked: Yup.boolean()
  })
  

  const getDefaultValues = () => {
  if(atomQuoteValue && atomQuoteValue.form && atomQuoteValue.form.clientInfos ){
    return {
      name: (atomQuoteValue.form.clientInfos as ConfiguratorClientInfos).client.name || '',
      firstname: (atomQuoteValue.form.clientInfos as ConfiguratorClientInfos).client.firstname || '',
      email: (atomQuoteValue.form.clientInfos as ConfiguratorClientInfos).client.email || '',
      phone: (atomQuoteValue.form.clientInfos as ConfiguratorClientInfos).client.phone || '',
    }
  }
  };

  const {
    control, handleSubmit
  } = useForm<TestFormValues>({
      mode: 'onChange',
      defaultValues: getDefaultValues(),
      resolver: yupResolver(testValidationSchema)
    });

    const onSubmit = (values: TestFormValues) => {

      const isLimitedCommercial = isCommercial ? true : false;

      setLoading(true);
      updateConfigClient(configIds, {
        client: {
          name: values.name,
          firstname: values.firstname,
          email: values.email,
          phone: values.phone,
        },
        dealer: (atomQuoteValue?.form?.clientInfos as ConfiguratorClientInfos).dealer}, 
        lang?.language
        ).then((response: any) => {
        const quoteValue: any = {
          form: {
            ...response.data.form,
            clientInfos: {
              client: {
                name: values.name,
                firstname: values.firstname,
                email: values.email,
                phone: values.phone,
              },
              dealer: (atomQuoteValue?.form?.clientInfos as ConfiguratorClientInfos).dealer
            },
            distributorOptions :{
              commentary :{
                description : values.info
              }
            }
          },
          configuration: {
            ...response.data.configuration,
            newATNR: response.data.configuration?.newATNR ?? null,
            newORNO: response.data.configuration?.newORNO ?? null,
            is_limited_commercial: isLimitedCommercial
          }
        };    
        quoteToOrder(quoteValue as QuoteGenerationType, configIds.dealerslist)
          .then((quoteResult: AxiosResponse<void>) => {
            toast.success(t('toast_order_success'));
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              toast.error('Une erreur est survenue lors du chargement des données');
            } else {
              toast.error(t('toast_save_order_error'));
              console.log('error: ', error);
              toast.error('Une erreur est survenue lors du chargement des données');
            }
          })
          .finally(() => {
            setLoading(false);
            onClose();
          });
      }).catch((error : any) => {
        toast.error(t('toast_save_order_error'));
        setLoading(false);
        onClose();
      });
    };
    
    
  const [isChecked, setIsChecked] = useState(false);

  const checkHandler = () => {
    setIsChecked(!isChecked);
  }


  return (
    <div className="order-confirmation-modal-content">
      <div className="order-confirmation-modal-content__title">{t('order_confirmation')}</div>

      <div className="order-confirmation-modal-content__header">
        <div className="order-confirmation-modal-content__info">
          <div className="order-confirmation-modal-content__subtitle">{t('your_order')}</div>
          <div className="order-confirmation-modal-content__brand">{atomQuoteValue && atomQuoteValue.configuration && atomQuoteValue.configuration.brand ? atomQuoteValue.configuration.brand : null}</div>
          <div className="order-confirmation-modal-content__model">{atomQuoteValue && atomQuoteValue.configuration && atomQuoteValue.configuration.config_model ? atomQuoteValue.configuration.config_model : null}</div>
          <div className="order-confirmation-modal-content__model">{atomQuoteValue && atomQuoteValue.configuration && atomQuoteValue.configuration.model ? atomQuoteValue.configuration.model : null}</div>
        </div>
        <div className="order-confirmation-modal-content__info">
          <div className="order-confirmation-modal-content__subtitle">{t('your_dealer_information')}</div>
          <div className="order-confirmation-modal-content__dealer--bold">{dealer?.name}</div>
          <div className="order-confirmation-modal-content__dealer">{dealer?.address}</div>
          <div className="order-confirmation-modal-content__dealer">{dealer?.email}</div>
        </div>
      </div>

      <div className="order-confirmation-modal-content__body">
        <form className="order-confirmation-modal-content__form" onSubmit={handleSubmit(onSubmit)}>
          <div className="order-confirmation-modal-content__cards">
            <div className="order-confirmation-modal-content__card">
              <div className="order-confirmation-modal-content__subtitle">{t('your_additional_info')}</div>
              <div className="order-confirmation-modal-content__text-area">            
                  <TextArea name="info" id="info" placeholder={t('complementary_info_placeholder')} control={control} label={""}   maxLength={20}/>
              </div>
            </div>
            <div className="order-confirmation-modal-content__card">
              <div className="order-confirmation-modal-content__subtitle">{t('your_client')}</div>
              <TextInput id="1" type="text" name="name" label="" placeholder={t('profile_lastname')} control={control} />
              <TextInput id="2" type="text" name="firstname" label="" placeholder={t('profile_firstname')} control={control} />
              <TextInput id="3" type="text" name="email" label="" placeholder={t('profile_email')} control={control} />
              <TextInput id="4" type="text" name="phone" label="" placeholder={t('profile_phone')} control={control} />
            </div>
          </div>

          <div className="order-confirmation-modal-content__checkbox">
            <input type="checkbox" className={`checkbox__input ${isChecked? "checkbox__input--checked" : ""}`}
              checked={isChecked} onChange={checkHandler} />
           <label className="checkbox__form-label" htmlFor="checkbox">
              {t('CGV_acceptance_part_1')}{' '}
              <a href="/cgv/cgv-fr.pdf" target="_blank">
                {t('CGV_acceptance_part_2')}{' '}
              </a>
              {t('CGV_acceptance_part_3')}
            </label>
            </div>
          <div className="order-confirmation-modal-content__buttons">
            <Button type={ButtonType.TERTIARY} text="Annuler" onClick={onClose} />
            <Button type={ButtonType.PRIMARY} text="Commander" onClick={handleSubmit(onSubmit)} disabled={!isChecked}/>
          </div>

        </form>
      </div>

    </div>
  );
}

export default OrderConfirmationModalContent;

