import { FunctionComponent, memo } from "react";
import './configurator-technical-details.scss';
import { useTranslation } from 'react-i18next';
import { formatPrice } from "_utils";
import { GreyCardSeats } from "_components";
import { animated } from "@react-spring/web";
import { useEntrance } from "_hooks";
import { ConfiguratorConfig } from "_api";

type ConfiguratorTechnicalDetailsProps = {
  config: ConfiguratorConfig
}

const ConfiguratorTechnicalDetails: FunctionComponent<ConfiguratorTechnicalDetailsProps> = ({ config }) => {
  const { t } = useTranslation();
  const entrance = useEntrance([config.calculated_price_vehicle, config.weight_vehicle, config.seats_number]);

  return (
    <animated.div className="configurator-technical-details" style={entrance}>
      <div className="configurator-technical-details__price-info">
        <div className="configurator-technical-details__label-price">{t('price_incl_tax')}</div>
        <div className="configurator-technical-details__price">
          {config.calculated_price_vehicle !== null ? formatPrice(config.calculated_price_vehicle) : null}</div>
      </div>
      <div className="configurator-technical-details__other-infos">

        {/* For camping cars only */}
        {config.vehicle_type === "1" && config.seats_number !== null ? (
          <div className="configurator-technical-details__seats">
            <span className="configurator-technical-details__label">{t('grey_card_seats')} : </span>
            <GreyCardSeats value={config.seats_number} total={null} />
          </div>
        ) : null}


        <div className={`configurator-technical-details__weight ${config.vehicle_type === '2' ? 'configurator-technical-details__weight--caravan' : ''}`}>
          {/* For caravans only */}
          {config.gvwd !== null ? (
            <div>
              <span className="configurator-technical-details__label">{t('GVW')} : </span>
              <span>{config.gvwd}{t('weight_measure_unit')}</span>
            </div>
          ) : null}
          <div>
            <span className="configurator-technical-details__label">{t('vehicle_weight')} : </span>
            <span>{config.actual_weight_vehicle}{t('weight_measure_unit')}</span>
          </div>
        </div>
        <div className="configurator-technical-details__payload">
          <span className="configurator-technical-details__label">{t('payload')} : </span>
          <span className="configurator-technical-details__charge">{config.minimum_payload?.toFixed(1)}{t('weight_measure_unit')}</span>
        </div>
      </div>

    </animated.div>
  );
}

export default memo(ConfiguratorTechnicalDetails);