import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  PageTitle,
  LocalLoader,
  PicturesLoader,
  Configurator,
  OptionValueType,
  FinitionOptionValueType,
  VerticalValueSwitchType,
} from "_components";
import {
  ClientInfosUpdatedValue,
  ConfiguratorForm,
  ConfiguratorResponseType,
  DistributorOptionsUpdatedValue,
  SingleSelectionUpdatedValue,
  getConfig,
  updateConfigAPI,
  updateConfigPTAC,
  updateConfigCG,
  ConfiguratorClientInfos,
  ConfiguratorFormMultipleSelection,
  InfoClient,
} from "_api";
import { AxiosResponse } from "axios";
import { useRecoilState, useSetRecoilState } from "recoil";
import atomConfigIds from "_atoms/atomConfigIds";
import { toast } from "react-toastify";
import { cloneDeep, isEmpty } from "lodash";
import "./configurator-page.scss";
import { atomDebugConfig, atomQuote, atomUserLang } from "_atoms";
import { useNavigate } from "react-router-dom";
import { useLoading } from "_hooks";

const ConfiguratorPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [configIds, setConfigIds] = useRecoilState(atomConfigIds);
  const [debugConfig, setDebugConfig] = useRecoilState(atomDebugConfig);
  const previousForm = useRef<ConfiguratorForm | null>(null);
  const clientMail = useRef<string | null>(null);
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const setQuote = useSetRecoilState(atomQuote);
  const [lang] = useRecoilState(atomUserLang);

  const dealerMail = useRef<string | null>(null);
  const [configuration, setConfiguration] = useState<ConfiguratorResponseType>({
    form: null,
    pictures: [],
    configuration: null,
    loaded: false,
    updateLoaded: true,
    vehicle_type: "1",
    overweight: false,
    dealerList: [],
    timeStamp: new Date().toString(),
  });

  useEffect(() => {
    setDebugConfig(null);
  }, []);

  useEffect(() => {
    setDebugConfig(configuration);
  }, [configuration]);

  const onPTACChange = (id: string, ptac: string, ATID: string) => {
    setConfiguration((previousConfiguration) => {
      return {
        ...previousConfiguration,
        updateLoaded: false,
      };
    });
    updateConfigPTAC(configIds.atnr, configIds, ATID, ptac, id, lang?.language
      ).then(
      (result) => {
        setConfiguration((previousConfiguration) => {
          return {
            ...previousConfiguration,
            form: result.data.form,
            overweight : result.data.overweight,
            configuration: result.data.configuration,
            updateLoaded: true,
          };
        });
      },
      (error) => {
        toast.error(t("toast_get_data_error"));
        setConfiguration({
          form: null,
          configuration: null,
          loaded: true,
          updateLoaded: true,
          pictures: [],
          vehicle_type: "1",
          overweight: false,
          dealerList: [],
          timeStamp: new Date().toString(),
        });
        console.error("error: ", error);
      }
    );
  };

  const onCGChange = () => {
    setConfiguration((previousConfiguration) => {
      return {
        ...previousConfiguration,
        updateLoaded: false,
      };
    });
    updateConfigCG(
      configIds.atnr,
      configIds,
      configuration.configuration?.seats_number || null,
      lang?.language
    ).then(
      (result) => {
        setConfiguration((previousConfiguration) => {
          return {
            ...previousConfiguration,
            form: result.data.form,
            configuration: result.data.configuration,
            overweight : result.data.overweight,
            updateLoaded: true,
          };
        });
      },
      (error) => {
        toast.error(t("toast_get_data_error"));
        setConfiguration({
          form: null,
          configuration: null,
          loaded: true,
          updateLoaded: true,
          pictures: [],
          vehicle_type: "1",
          overweight: false,
          dealerList: [],
          timeStamp: new Date().toString(),
        });
        console.log("error: ", error);
      }
    );
  };

  const onRollback = () => {
    rollbackConfiguration();
  };

  // Initial configuration loading.
  const loadConfiguration = () => {
    getConfig(configIds, lang?.language).then(
      (result: AxiosResponse<ConfiguratorResponseType>) => {
        if (
          result &&
          result.data &&
          result.data.form &&
          (result.data.form.clientInfos as ConfiguratorClientInfos)
        ) {
          clientMail.current = (
            result.data.form.clientInfos as ConfiguratorClientInfos
          ).client.email;
          dealerMail.current = (
            result.data.form.clientInfos as ConfiguratorClientInfos
          ).dealer.email;
        }

        setConfiguration({
          form: result.data.form,
          configuration: result.data.configuration,
          loaded: !result.data.pictures.length,
          updateLoaded: true,
          pictures: result.data.pictures,
          vehicle_type: result.data.vehicle_type,
          overweight: result.data.overweight,
          dealerList: result.data.dealerList,
          timeStamp: new Date().toString(),
        });

        // Set atnr.
        setConfigIds((prev) => {
          return {
            ...prev,
            atnr: result.data.configuration?.ATNR || "",
            initialATNR: prev.initialATNR
              ? prev.initialATNR
              : result.data.configuration?.ATNR || "",
            initialORNO: prev.initialORNO
              ? prev.initialORNO
              : result.data.configuration?.ORNO || "",
            //orno: prev.orno ? prev.orno : result.data.configuration?.ORNO || ''
            orno: result.data.configuration?.ORNO || "",
          };
        });
      },
      (error) => {
        const notif =
          t("toast_get_data_error") +
          " [" +
          (error && error.response && error.response.status
            ? error.response.status.toString()
            : "CORS") +
          "]";
        toast.error(notif);
        setConfiguration({
          form: null,
          configuration: null,
          loaded: true,
          updateLoaded: true,
          pictures: [],
          vehicle_type: "1",
          overweight: false,
          dealerList: [],
          timeStamp: new Date().toString(),
        });
        console.error("error: ", error);
      }
    );
  };

  const getUpdateParams = (
    lastModifiedValue: FormData | unknown,
    name: string
  ) => {
    let regularParams: SingleSelectionUpdatedValue | null = null;
    let clientInfosParams: ClientInfosUpdatedValue | null = null;
    let distributorOptionsParams: DistributorOptionsUpdatedValue | null = null;

    if (name && typeof name === "string") {
      const nameParts = name.length ? name.split(".") : name;
      if (nameParts.length > 1) {
        // Carrier.
        if (nameParts[0] === "carrier") {
          regularParams = {
            id: (lastModifiedValue as FinitionOptionValueType)._id || null,
            name: nameParts[1],
            values: (lastModifiedValue as FinitionOptionValueType).value,
          };
        }
        // Finish.
        else if (nameParts[0] === "finish") {
          regularParams = {
            id: (lastModifiedValue as OptionValueType)._id || null,
            name: nameParts[1],
            values: (lastModifiedValue as OptionValueType).value,
          };
        }
        // Pack.
        else if (nameParts[0] === "pack") {
          let id = null;
          if (
            configuration &&
            configuration.form &&
            (configuration.form.pack as ConfiguratorFormMultipleSelection).items
          ) {
            configuration &&
              configuration.form &&
              (
                configuration.form.pack as ConfiguratorFormMultipleSelection
              ).items.forEach((item) => {
                if (item.value === nameParts[1]) {
                  id = item.id;
                }
              });
          }
          // const value = (lastModifiedValue as VerticalValueSwitchType) ? "Y" : "N"
          regularParams = {
            id: id,
            name: nameParts[1],
            values: (lastModifiedValue as VerticalValueSwitchType) ? "Y" : "N",
          };
        }
        // Carrier option.
        else if (nameParts[0] === "carrierOption") {
          regularParams = {
            id: (lastModifiedValue as OptionValueType)._id || null,
            name: nameParts[1],
            values: (lastModifiedValue as OptionValueType).value,
          };
        }
        // Cell option.
        else if (nameParts[0] === "cellOption") {
          regularParams = {
            id: (lastModifiedValue as OptionValueType)._id || null,
            name: nameParts[1],
            values: (lastModifiedValue as OptionValueType).value,
          };
        }
        // Distributor options
        else if (nameParts[0] === "distributorOptions") {
          distributorOptionsParams =
            lastModifiedValue as DistributorOptionsUpdatedValue;
        } else if (nameParts[0] === "clientInfos") {
          clientMail.current =
            (lastModifiedValue as ClientInfosUpdatedValue).client.email || null;
          dealerMail.current =
            (lastModifiedValue as ClientInfosUpdatedValue).dealer.email || null;
          clientInfosParams = {
            client: (lastModifiedValue as ClientInfosUpdatedValue).client,
            dealer: (lastModifiedValue as ClientInfosUpdatedValue).dealer,
          };
        }
      } else if (name === "clientInfos") {
        clientMail.current =
          (lastModifiedValue as ClientInfosUpdatedValue).client.email || null;
        dealerMail.current =
          (lastModifiedValue as ClientInfosUpdatedValue).dealer.email || null;
        clientInfosParams = {
          client: (lastModifiedValue as ClientInfosUpdatedValue).client,
          dealer: (lastModifiedValue as ClientInfosUpdatedValue).dealer,
        };
      }
    }
    return {
      regularParams,
      clientInfosParams,
      distributorOptionsParams,
    };
  };
  // Update configuration.
  const updateConfiguration = (
    lastModifiedValue: FormData | unknown,
    name: string
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      // Set loader.
      setConfiguration((previousConfiguration: ConfiguratorResponseType) => {
        if (previousConfiguration.form) {
          //setPreviousForm(cloneDeep(previousConfiguration.form));
          previousForm.current = cloneDeep(previousConfiguration.form);
        }
        return {
          ...previousConfiguration,
          updateLoaded: false,
        };
      });

      // Update config.
      const params = getUpdateParams(lastModifiedValue, name);
      // updateConfigAPI();
      updateConfigAPI(
        configIds.atnr,
        configIds,
        params.regularParams,
        params.clientInfosParams,
        params.distributorOptionsParams,
        clientMail.current,
        dealerMail.current,
        lang?.language
      ).then(
        (result: any) => {
          setConfiguration(
            (previousConfiguration: ConfiguratorResponseType) => {
              return {
                ...previousConfiguration,
                form: result.data.form,
                overweight: result.data.overweight,
                configuration: result.data.configuration,
                updateLoaded: true,
              };
            }
          );
          setQuote(result.data);
          setConfigIds((prev) => {
            return {
              ...prev,
              clientMail: (
                result.data.form.clientInfos as ConfiguratorClientInfos
              ).client.email,
              dealerMail: (
                result.data.form.clientInfos as ConfiguratorClientInfos
              ).dealer.email,
            };
          });
          resolve(result); // Resolve the promise with the result
        },
        (error: any) => {
          toast.error("Erreur lors de la modification des données");
          console.error("error: ", error);
          reject(error); // Reject the promise with the error
        }
      );
    });
  };

  const rollbackConfiguration = () => {
    if (previousForm.current) {
      setConfiguration((previousConfiguration: ConfiguratorResponseType) => ({
        ...previousConfiguration,
        form: previousForm.current,
      }));
      //setPreviousForm(null);
      previousForm.current = null;
    }
  };
  // Initial data loading.
  useLayoutEffect(() => {
    loadConfiguration();
  }, []);

  // Pictures loading.
  const onPicturesLoad = useCallback(() => {
    setConfiguration((prev) => ({ ...prev, loaded: true }));
  }, []);

  // Reload configuration on form change.
  const onFormChange = (
    lastModifiedValue: FormData | unknown,
    name: string
  ) => {
    updateConfiguration(lastModifiedValue, name);
  };
  const [clientInfo, setClientInfo] = useState<InfoClient>();

  const handleClientInfoChange = (client: InfoClient) => {
    setClientInfo(client);
    const clientFormData: any = {};
    clientFormData.client = {};
    clientFormData.client.firstname = client.firstName;
    clientFormData.client.name = client.lastName;
    clientFormData.client.phone = client.clientPhone;
    clientFormData.client.email = client.clientEmail;

    if (
      configuration &&
      configuration.form &&
      configuration.form.clientInfos &&
      (configuration.form.clientInfos as ConfiguratorClientInfos).dealer
    ) {
      clientFormData.dealer = (
        configuration.form.clientInfos as ConfiguratorClientInfos
      ).dealer;
    } else {
      clientFormData.dealer = {};
    }
    setQuote((prev: any) => ({
      ...prev,
      form: {
        ...(prev.form || {}),
        clientInfos: {
          ...(prev.form ? prev.form.clientInfos : {}),
          client: clientFormData.client,
          dealer: clientFormData.dealer,
        },
      },
      configuration: configuration.configuration,
    }));

    updateConfiguration(clientFormData, "clientInfos")
      .then(() => {
        setLoading(true);
        navigate("/quotepage");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error during configuration update:", error);
      });
  };

  const handlCreateQuote = () => {
    const clientFormData: any = {};
    clientFormData.client = {};
    if (
      configuration &&
      configuration.form &&
      configuration.form.clientInfos &&
      (configuration.form.clientInfos as ConfiguratorClientInfos).dealer
    ) {
      clientFormData.dealer = (
        configuration.form.clientInfos as ConfiguratorClientInfos
      ).dealer;
    } else {
      clientFormData.dealer = {};
    }

    setQuote((prev: any) => ({
      ...prev,
      form: {
        ...(prev.form || {}),
        clientInfos: {
          ...(prev.form ? prev.form.clientInfos : {}),
          client: clientFormData.client,
          dealer: clientFormData.dealer,
        },
      },
      configuration: configuration.configuration,
    }));

    updateConfiguration(clientFormData, "clientInfos")
      .then(() => {
        setLoading(true);
        navigate("/quotepage");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error during configuration update:", error);
      });
  };

  return (
    <div className="configurator-page">
      {/* Load configuration pictures */}
      {configuration.pictures && configuration.pictures.length ? (
        <PicturesLoader
          images={configuration.pictures}
          onLoad={onPicturesLoad}
        />
      ) : null}

      <PageTitle
        title={t("configurator_page_title")}
        description={t("configurator_page_description")}
      />

      {!configuration.loaded ? (
        <div className="configurator-page__local-loader-wrapper">
          <LocalLoader loadingMessage={t("configurator_loading_message")} />
        </div>
      ) : (
        <div className="configurator-page__configurator-wrapper">
          {!isEmpty(configuration.configuration) ? (
            <Configurator
              configuration={configuration}
              onFormChange={onFormChange}
              onPTACChange={onPTACChange}
              onCGChange={onCGChange}
              onRollback={onRollback}
              onClientChange={handleClientInfoChange}
              onCreateQuote={handlCreateQuote}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default memo(ConfiguratorPage);
