import { FunctionComponent, useEffect, useState } from "react";

type PicturesLoaderProps = {
  images: string[];
  onLoad: () => void;
};

const PicturesLoader: FunctionComponent<PicturesLoaderProps> = ({
  images,
  onLoad,
}) => {
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const [errorImages, setErrorImages] = useState<string[]>([]);

  const handleImageLoaded = (image: string) => {
    setLoadedImages((prev) => [...prev, image]);
  };

  const handleImageError = (image: string) => {
    setErrorImages((prev) => [...prev, image]);
  };

  useEffect(() => {
    if (loadedImages.length + errorImages.length === images.length) {
      onLoad();
    }
  }, [loadedImages, errorImages, images, onLoad]);

  return (
    <div style={{ display: "none" }}>
      {images.map((image) => (
        <img
          key={image}
          src={image}
          onLoad={() => handleImageLoaded(image)}
          onError={() => handleImageError(image)}
          alt=""
        />
      ))}
    </div>
  );
};

export default PicturesLoader;
