import { FunctionComponent } from "react";
import { 
  PageTitle, 
  HorizontalOptionValue, 
  OptionValueType, 
  Checkbox, 
  TextInput, 
  HorizontalFinitionOptionValue, 
  FinitionOptionValueType, 
  Modal, 
  Button,
  ButtonType,
  OrderConfirmationModalContent,
  ConflictedOptionsModalContent, 
  DiscountValidationModalContent
} from "_components";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import './option-page.scss';
import { useModal } from "_hooks";


type TestFormValues = {
  accessories: boolean,
  vip: boolean,
  safety: boolean,
  artic: boolean,
  visibility: boolean,
  frame: OptionValueType,
  gearbox: OptionValueType,
  store: boolean,
  roof: boolean,
  isofix: boolean,
  dinette: boolean,
  bicycle: boolean,
  nom: string,
  nomClient: string,
  prénom: string,
  email: string,
  téléphone: string,
  emailClient: string,
  téléphoneClient: string,
  label: string,
  color: FinitionOptionValueType
}

const gearboxOptions: OptionValueType[] = [
  {
    id: "manual",
    label: "Manuelle",
    // subLabel: "(de série)",
    price: 0,
    value: "manual",
    picture: null
  },
  {
    id: "auto",
    label: "Automatique",
    // subLabel: "(+3490 €)",
    price: 1500,
    value: "auto",
    picture: null
  }
];

const frameOptions: OptionValueType[] = [
  {
    id: "heavy",
    label: "3T5 Heavy",
    // subLabel: "(de série)",
    price: 0,
    value: "heavy",
    picture: null
  },
  {
    id: "vip",
    label: "3T5 Pack VIP",
    // subLabel: "(+345 €)",
    price: 320,
    value: "vip",
    picture: null
  },
  {
    id: "test",
    label: "Test",
    // subLabel: "(+345 €)",
    price: 345,
    value: "test",
    picture: null
  }
];

const finitionOptions: FinitionOptionValueType[] = [
  {
    id: "heavy",
    label: "3T5 Heavy",
    // subLabel: "(de série)",
    price: 0,
    value: "heavy",
    picture: "https://pics.porsche.com/rtt/iris?COSY-EU-100-1713c6eK12UC31P3T5JOCU%25hjdmiTDDmvMXlHWguCuq6Q44RtRHo9ZAaDjau5PwI7tGW3rNbZJNKXv9Z7KcQQ%25yFN5tFAsXrw4r3wo0qnqZr8MCnR4i84tV2YN2OmNyW1QGWgCWKMUuyO9I7KM8JihQ52DDgfNagKyX87TQ9br3uwrD1XEUA%25Y1O8uJWRoW%25h3xFHyTOtw9xeXi69skh5H"
  },
  {
    id: "vip",
    label: "3T5 Pack VIP",
    // subLabel: "(+345 €)",
    price: 150,
    value: "vip",
    picture: "https://pics.porsche.com/rtt/iris?COSY-EU-100-1713c6eK12UC31P3T5JOCU%25hjdmiTDDmvMXlHWguCuq6Q44RtRHo9ZAaDjau5PwI7tGW3rNbZJNKXv9Z7KcQQ%25yFN5tFAsXrw4r3wo0qnqZr8MCnR4i84tV2YN2OmNyW1QGWgCWKMUuyO9I7KM8JihQ52DDgfNagKyX87TQ9br3uwrD1XEUA%25Y1O8uJWRoW%25h3xFHyTOtw9xeXi69skh5H"
  },
  {
    id: "test",
    label: "Test",
    // subLabel: "(+345 €)",
    price: 345,
    value: "test",
    picture: "https://pics.porsche.com/rtt/iris?COSY-EU-100-1713c6eK12UC31P3T5JOCU%25hjdmiTDDmvMXlHWguCuq6Q44RtRHo9ZAaDjau5PwI7tGW3rNbZJNKXv9Z7KcQQ%25yFN5tFAsXrw4r3wo0qnqZr8MCnR4i84tV2YN2OmNyW1QGWgCWKMUuyO9I7KM8JihQ52DDgfNagKyX87TQ9br3uwrD1XEUA%25Y1O8uJWRoW%25h3xFHyTOtw9xeXi69skh5H"
  }
];

const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const OptionPage: FunctionComponent = () => {

  const { t } = useTranslation();

  const testValidationSchema = Yup.object().shape({
    accessories: Yup.boolean(),
    vip: Yup.boolean(),
    safety: Yup.boolean(),
    artic: Yup.boolean(),
    visibility: Yup.boolean(),
    frame: Yup.object().shape({
      id: Yup.string(),
      label: Yup.string(),
      suLabel: Yup.string(),
      value: Yup.string()
    }),
    gearbox: Yup.object().shape({
      id: Yup.string(),
      label: Yup.string(),
      suLabel: Yup.string(),
      value: Yup.string()
    }),
    store: Yup.boolean(),
    roof: Yup.boolean(),
    isofix: Yup.boolean(),
    dinette: Yup.boolean(),
    bicyle: Yup.boolean(),
    nom: Yup.string(),
    nomClient: Yup.string(),
    prénom: Yup.string(),
    email: Yup.string().email(t('email_error')),
    téléphone: Yup.string().matches(phoneRegex, t('number_error')),
    emailClient: Yup.string().email(t('email_error')),
    téléphoneClient: Yup.string().matches(phoneRegex, t('number_error')),
    label: Yup.string(),
    color: Yup.object().shape({
      id: Yup.string(),
      label: Yup.string(),
      suLabel: Yup.string(),
      value: Yup.string(),
      picture: Yup.string()
    }),
  })

  const {
    control, handleSubmit
  } = useForm<TestFormValues>({
    resolver: yupResolver(testValidationSchema),
    mode: 'onChange',
    defaultValues: {
      accessories: true,
      vip: false,
      safety: false,
      artic: false,
      visibility: false,
      frame: frameOptions[0],
      gearbox: gearboxOptions[0],
      store: false,
      roof: false,
      isofix: false,
      dinette: false,
      bicycle: false,
      nom: "",
      nomClient: "",
      prénom: "",
      email: "",
      téléphone: "",
      emailClient: "",
      label: "",
      color: finitionOptions[0]
    }
  })

  const onSubmit = (values: TestFormValues) => {
    console.log(values);
  }

  const { setModal } = useModal();

  return (
    <div className="configurator-page">
      <PageTitle title="Option page" />
      <div className="option-page__main-content">
        <div className="option-container">
          <form onSubmit={handleSubmit(onSubmit)}>

            {/* <VerticalValueSwitch name="accessories" title="Pack + accessoires (de série)" price={10} description="Châssis 35L, antibrouillards avec cornering⁽ⁱⁱ⁾, Système multimédia avec commandes au volant et Caméra de recul⁽³⁾" control={control} />
            <VerticalValueSwitch name="vip" title="Pack + vip (+ xxx €)" price={10} description="Châssis 35L, antibrouillards avec cornering⁽ⁱⁱ⁾, Système multimédia avec commandes au volant et Caméra de recul⁽³⁾" control={control} />
            <VerticalValueSwitch name="safety" title="Pack + safety (+ xxx €)" price={10} description="Châssis 35L, antibrouillards avec cornering⁽ⁱⁱ⁾, Système multimédia avec commandes au volant et Caméra de recul⁽³⁾" control={control} />
            <VerticalValueSwitch name="artic" title="Pack + artic (+ xxx €)" price={10} description="Châssis 35L, antibrouillards avec cornering⁽ⁱⁱ⁾, Système multimédia avec commandes au volant et Caméra de recul⁽³⁾" control={control} />
            <VerticalValueSwitch name="visibility" title="Pack + visibilité (+ xxx €)" price={10} description="Châssis 35L, antibrouillards avec cornering⁽ⁱⁱ⁾, Système multimédia avec commandes au volant et Caméra de recul⁽³⁾" control={control} /> */}

            <div className="option-horizontal-value-container">
              <HorizontalOptionValue label="frame" options={frameOptions} name="frame" control={control} />
              <HorizontalOptionValue label="gearbox" options={gearboxOptions} name="gearbox" control={control} />
            </div>

            <div className="option-container__option-cellule">
              <Checkbox id="1" name="store" title="Store d'occultation cabine" price={60} weight={1} control={control} />
              <Checkbox id="2" name="roof" title="Toit relevable" price={60} weight={20} control={control} />
              <Checkbox id="3" name="isofix" title="Fixation Isofix" price={60} weight={10} control={control} />
              <Checkbox id="4" name="dinette" title="Kit d'extension dinette" price={60} weight={33} control={control} />
              <Checkbox id="5" name="bicycle" title="Porte vélo" price={61} weight={55} control={control} />
            </div>

            <div className="option-container__option-customer-info">
              <div className="option-container__info">
                <span className="option-container__subtitle">{t('distributor')} *</span>
                <div className="option-container__input-container">
                  <TextInput id="1" type="text" name="nom" label="name" control={control} />
                </div>
                <div className="option-container__input-container">
                  <TextInput id="2" type="text" name="email" label="email" control={control} />
                </div>
                <div className="option-container__input-container">
                  <TextInput id="7" type="text" name="téléphone" label="téléphone" control={control} />
                </div>
              </div>
              <div className="option-container__info">
                <span className="option-container__subtitle">{t('customer')}</span>
                <div className="option-container__input-container">
                  <TextInput id="3" type="text" name="nomClient" label="nom" control={control} />
                </div>
                <div className="option-container__input-container">
                  <TextInput id="4" type="text" name="prénom" label="prénom" control={control} />
                </div>
                <div className="option-container__input-container">
                  <TextInput id="5" type="text" name="emailClient" label="email" control={control} />
                </div>
                <div className="option-container__input-container">
                  <TextInput id="6" type="text" name="téléphoneClient" label="téléphone" control={control} />
                </div>
              </div>
            </div>

            <div className="option-container__option-finition">
              <HorizontalFinitionOptionValue options={finitionOptions} name="color" label="couleur de carrosserie" control={control} />
              <HorizontalFinitionOptionValue options={finitionOptions} name="color" label="couleur de carrosserie" control={control} />
            </div>

            <input type="submit" value="valider" />


          </form >
          <Modal><OrderConfirmationModalContent onClose={() => setModal(false)} /></Modal>
          <Button type={ButtonType.PRIMARYSMALL} text="Commander" onClick={() => setModal(true)} />
        </div >
            <Modal><ConflictedOptionsModalContent onClose={() => setModal(false)} /></Modal>
            <Button type={ButtonType.PRIMARYSMALL} text="Conflit d'options" onClick={() => setModal(true)} />

            <Modal><DiscountValidationModalContent onClose={() => setModal(false)} /></Modal>
            <Button type={ButtonType.PRIMARYSMALL} text="Discount popin" onClick={() => setModal(true)} />
      </div >
    </div >
  );
}

export default OptionPage;

