import { atom } from 'recoil';
import { ConfigurationIds } from '_types';
import customStorage from './customStorage';

const key = 'configurationIds';

const atomConfigIds = atom<ConfigurationIds>({
  key,
  default: {
    vehicle_id: '',
    cuno: '',
    faci: '',
    whlo: '',
    itno: '',
    danr: '',
    oref: '',
    dealer: null,
    atav: '',
    atnr: '',
    orno: null,
    availability_id: '',
    newAtnr: null,
    newOrno: null,
    clientMail: null,
    dealerMail: null,
    initialATNR: null,
    initialORNO: null,
    dealerslist: [],
    millesime: null,
    slot: null,
    userMail: null,
    scom : '',
    country : '',
    dead_line_production_date: '',
    stat: '',
    stas: '',
    fidt: '',
    dead_line_due_date: '',
  },
  effects: customStorage(key),
});

export default atomConfigIds;
