import { ConfiguratorConfig, VehicleResult } from "_api";
import { OptionSelect } from "_components";
import { TFunction } from "i18next";


export const langsOptions = [
  {
    label: 'Français', value: 'fr-FR', name: 'fr',
  },
  {
    label: 'English', value: 'en-US', name: 'en',
  },
  {
    label: 'Español', value: 'es-ES', name: 'es',
  },
  {
    label: 'German', value: 'de-DE', name: 'de',
  },
  {
    label: 'Italiano', value: 'it-IT', name: 'it',
  }
]

const diffWeeks = (dt2: Date, dt1: Date) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60 * 24 * 7);
  return Math.round(diff);
}


export const getOptionfromLocale = (local: string) => {
  const langOption = langsOptions.find((lang) => lang.value === local);
  if (langOption) return langOption as OptionSelect;
  else return langsOptions[0] as OptionSelect;
}

export function formatPrice(number: number) {
  return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(number);
}

export function padNumber(num: number, size: number) {
  let _num = num.toString();
  while (_num.length < size)
    _num = "0" + _num;
  return _num;
}

export function isEmptyObject(obj: object) {
  return Object.getOwnPropertyNames(obj).length === 0;
}

export function formatDate(date: string) {
  const dateRegex = /^\d{4}-\d{2}$/;
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
  ];
  if (!dateRegex.test(date)) {
    return '';
  } else {
    const parts = date.split("-");
    const year = parts[0];
    const monthIndex = parseInt(parts[1]) - 1;
    const month = months[monthIndex];
    return `${month} ${year}`;
  }
}

export function getVehicleStatusWording(config: ConfiguratorConfig, t: TFunction) {
  if (config.availability == 'disponible_ou_en_stock_concession')
    return t('vehicle_status_available');
  else if (config.availability == 'disponible_en_stock_usine_ou_sous_deux_semaines')
    return t('vehicle_status_available_factory');
  else if (config.availability == 'commande_usine_ou_production_sous_x_semaines')
    return t('vehicle_status_factory_ordered');
  else if (config.availability == 'prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine')
    return t('vehicle_status_in_production');
  else if (config.availability === 'a_configurer_ou_contactez_votre_adv') {
    return t('vehicle_status_configurable');
  }
  else {
    return config.availability;
  }
}


function addDaysToDate(date: Date, days: number): Date {
  const result: Date = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}


    export function getVehicleDateOrStatus(config: ConfiguratorConfig, t: TFunction) {
      
      const NB_WEEKS_MARGIN = 2;
    // 1. Stock concess / disponible
      if (config.availability == 'disponible_ou_en_stock_concession') {
        return t('vehicle_status_stock_nb_label');
      }
      // 2. Stock usine / disponible en stock usine
      else if (config.availability == 'disponible_en_stock_usine_ou_sous_deux_semaines') {
      if (config.STAT === "STK") {
          if (parseInt(config.STAS) === 2 || parseInt(config.STAS) === 3) {
              var newDeadlineDate = addDaysToDate(new Date(), parseInt(config.dead_line_production_date));
              var weeks = Number(diffWeeks(  new Date(newDeadlineDate),new Date()));
              return weeks > 0 ? t('vehicle_status_period_prefix') + ' ' + weeks + ' ' + t('vehicle_status_period_suffix') : null;
          } else if (parseInt(config.STAS) === 1) {
              var newDeadlineDate = addDaysToDate(new Date(config.FIDT),parseInt(config.dead_line_due_date) );
              var weeks =Number(diffWeeks( new Date(newDeadlineDate),new Date() ) );
              return weeks > 0 ? t('vehicle_status_period_prefix') + ' ' + weeks + ' ' + t('vehicle_status_period_suffix') : null;
          }
      }
      }
      // 3. Commandé usine
      else if (config.availability == 'commande_usine_ou_production_sous_x_semaines' && config.production_date) {
          var weeks = Number(diffWeeks(new Date(config.production_date), new Date()) + NB_WEEKS_MARGIN);
          return weeks > 0 ? t('vehicle_status_period_prefix') + ' ' + weeks + ' ' + t('vehicle_status_period_suffix') : null;
      }
      // 4. Production usine ()
      else if (config.availability == 'prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine' && config.production_date) {
          var newDeadlineDate = addDaysToDate(new Date(config.production_date),parseInt(config.dead_line_production_date) );
          var weeks = Number(diffWeeks(new Date(newDeadlineDate), new Date()));
          return weeks > 0 ? t('vehicle_status_period_prefix') + ' ' + weeks + ' ' + t('vehicle_status_period_suffix') : null;
      }

      // 5. A configurer // pas de date voulue // inconnu
      else
          return null;
    }

export function getVehicleButtonWording(vehicle: VehicleResult, t: TFunction) {
  if (vehicle.availability == 'disponible_ou_en_stock_concession')
    return t('vehicle_button_wording_available');
  else if (vehicle.availability == 'disponible_en_stock_usine_ou_sous_deux_semaines')
    return t('vehicle_button_wording_available_factory');
  else if (vehicle.availability == 'commande_usine_ou_production_sous_x_semaines')
    return t('vehicle_button_wording_factory_ordered');
  else if (vehicle.availability == 'prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine')
    return t('vehicle_button_wording_next_availability');
  else
    return t('vehicle_button_wording_other');
}
