import { FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "_components";
import './page-title.scss';

export type PageTitleProps = {
  title: string;
  description?: ReactNode | string;
  searchPage?: boolean;
  onPlanningPageClick?: () => void;
  enablePlanningPageButton?: boolean;
}

const PageTitle: FunctionComponent<PageTitleProps> = (props: PageTitleProps) => {
  const { t } = useTranslation();

  return (
    <div className="page-title">
      <div className="page-title__main-wrapper">
        <div className="page-title__title-container">
          <h1 className="page-title__title">{props.title}</h1>
          <div className="page-title__description">{props.description}</div>
        </div>
        {props.searchPage ? (
          <div className="page-title__action-container">
            <div className="page-title__button-label">{t('search_page_title_action_label')}</div>
            <Button disabled={!props.enablePlanningPageButton} type={ButtonType.TERTIARY} chevronRight={true} text={t('search_page_title_action_button')} onClick={() => props.onPlanningPageClick ? props.onPlanningPageClick() : null} />
          </div>
        ) : null}
      </div>

    </div>
  );
}

export default PageTitle;

