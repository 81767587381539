import { FunctionComponent, useEffect, useState } from "react";
import './debug-tool.scss';
import atomConfigIds from "_atoms/atomConfigIds";
import { useRecoilState } from "recoil";
import JSONFormatter from "json-formatter-js";
import { atomDebugConfig, atomQuote } from "_atoms";

const DebugTool: FunctionComponent = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [configIds, setConfigIds] = useRecoilState(atomConfigIds);
  // const [atomQuoteValue, setAtomQuoteValue] = useRecoilState(atomQuote);
  const [debugConfig, setDebugConfig] = useRecoilState(atomDebugConfig);

  useEffect(() => {
    const formatter = new JSONFormatter(configIds);
    const renderedJSON = formatter.render();  
    const targetDiv = document.getElementById('configIds');
    if (targetDiv) {
      targetDiv.innerHTML = '';
      targetDiv.appendChild(renderedJSON);
    }
  }, [configIds]);

  useEffect(() => {
    const formatter = new JSONFormatter(debugConfig);
    const renderedJSON = formatter.render();  
    const targetDiv = document.getElementById('configuration');
    if (targetDiv) {
      targetDiv.innerHTML = '';
      targetDiv.appendChild(renderedJSON);
    }
  }, [debugConfig]);

  return (
    <div className={`debug-tool ${isOpened ? 'debug-tool--opened' : ''}`}>
      <div className="debug-tool__title-container">
        <div className="debug-tool__title">Debug tool</div>
        {!isOpened ? (
          <button className="debug-tool__extend-button" onClick={() => setIsOpened(true)}></button>
        ): (
          <button className="debug-tool__minimize-button" onClick={() => setIsOpened(false)}></button>
        )}
      </div>
      <div className="debug-tool__main-content">

          <div className="debug-tool__section">
            <div className="debug-tool__section-title">parameters</div>
            <div className="debug-tool__section-content">
              <div id="configIds"></div>
            </div>
          </div>

          <div className="debug-tool__section">
            <div className="debug-tool__section-title">response</div>
            <div className="debug-tool__section-content debug-tool__section-content--large">
              <div id="configuration"></div>
            </div>
          </div>

      </div>
    </div>
  );
}

export default DebugTool;