import { useState, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextInput } from "_components";
import '../AdditionalOptionItem/additional-option-item.scss';

type StartUpCostsOptionType = {
  id: number | null;
  startupPrice: number;
  greycardPrice: number;
};

type StartUpCostsOptionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
};

const StartUpCostsOption: FunctionComponent<StartUpCostsOptionProps> = ({ control, name }) => {

  const [updateMode, setUpdateMode] = useState(true);
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const [option, setOption] = useState<StartUpCostsOptionType>({
          id: field.value.id,
          startupPrice: field.value.startupPrice,
          greycardPrice: field.value.greycardPrice,
        });

        return (
          <div className="additional-option">
            <div className="additional-option__title">{t('setup_fees')}</div>

            <div className="additional-option-item additional-option__item__startup-cost">
              <TextInput
                id="startup-cost"
                name="price"
                label={t('startup_costs')}
                type="number"
                value={option.startupPrice}
                disabled={!updateMode}
                onChange={(e) => setOption({ ...option, startupPrice: Number((e.target as HTMLInputElement).value) })}
              /> {t('price_currency')}
              <TextInput
                id="graycard-fees"
                name="price"
                label={t('gray_card_fees')}
                type="number"
                value={option.greycardPrice}
                disabled={!updateMode}
                onChange={(e) => setOption({ ...option, greycardPrice: Number((e.target as HTMLInputElement).value) })}
              /> {t('price_currency')}

              {updateMode ? (
                <div className="save-btn-container">
                  <button
                    className="save-btn"
                    onClick={() => { field.onChange(option); setUpdateMode(false); }}
                    disabled={typeof option.startupPrice !== 'number' || typeof option.greycardPrice !== 'number'}>
                    {t('validate')}
                  </button>
                </div>
              ) : (
                <button className="action-button--update-btn" onClick={() => setUpdateMode(true)}></button>
              )}
            </div>
          </div>
        );
      }}
    />
  )
};

export default StartUpCostsOption;