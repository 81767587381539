import { animated } from "@react-spring/web";
import { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import ButtonType from "../Button/ButtonType";
import { useEntrance } from "_hooks";
import './configurator-common-details.scss';
import { ConfiguratorConfig } from "_api";
import { getVehicleDateOrStatus, getVehicleStatusWording } from "_utils";

type ConfiguratorCommonDetailsProps = {
  config: ConfiguratorConfig;
}

const ConfiguratorCommonDetails: FunctionComponent<ConfiguratorCommonDetailsProps> = ({ config }) => {
  const { t } = useTranslation();
  const entrance = useEntrance([config.seats_number]);

  return (
    <animated.div className="configurator-common-details" style={entrance}>

      <div className="configurator-common-details__main-container">
        <div className="configurator-common-details__brand">{config.brand}</div>
        <div className="configurator-common-details__config-name-model-container">
          <div className="configurator-common-details__config-name">{config.config_model}</div>
          <div className="configurator-common-details__model">{config.model}</div>
        </div>
        <div className="configurator-common-details__icons-container">

          {/* For camping cars only */}
          {config.vehicle_type === "1" && config.seats_number !== null ? (
            <div className="configurator-common-details__item">
              <div className="configurator-common-details__item-icon configurator-common-details__item-icon--seats"></div>
              <div className="configurator-common-details__item-value">{config.seats_number}</div>
            </div>
          ) : null}

          <div className="configurator-common-details__item">
            <div className="configurator-common-details__item-icon configurator-common-details__item-icon--beds"></div>
            <div className="configurator-common-details__item-value">{config.beds_number}</div>
          </div>

          <div className="configurator-common-details__item">
            <div className="configurator-common-details__item-icon configurator-common-details__item-icon--cutlery"></div>
            <div className="configurator-common-details__item-value">{config.cutlery}</div>
          </div>

          {config.width !== null ?
            <div className="configurator-common-details__item">
              <div className="configurator-common-details__item-icon configurator-common-details__item-icon--width"></div>
              <div className="configurator-common-details__item-value">{config.width.toFixed(2)}
                {t('length_measure_unit')}
              </div>
            </div>
            : null}

          {config.height !== null ?
            <div className="configurator-common-details__item">
              <div className="configurator-common-details__item-icon configurator-common-details__item-icon--height"></div>
              <div className="configurator-common-details__item-value">{config.height.toFixed(2)}
                {t('length_measure_unit')}
              </div>
            </div>
            : null}

          {config.length !== null ?
            <div className="configurator-common-details__item">
              <div className="configurator-common-details__item-icon configurator-common-details__item-icon--length"></div>
              <div className="configurator-common-details__item-value">{config.length.toFixed(2)}
                {t('length_measure_unit')}
              </div>
            </div>
            : null}

          {/* For caravans only */}
          {config.vehicle_type === "2" && config.maximum_authorized_actual_weight_vehicle !== null ? (
            <div className="configurator-common-details__item">
              <div className="configurator-common-details__item-icon configurator-common-details__item-icon--ptac"></div>
              <div className="configurator-common-details__item-value">{config.gvwd}{t('weight_unit')}</div>
            </div>
          ) : null}

        </div>
      </div>

      <div className="configurator-common-details__secondary-container">
        <div>
          <div className="configurator-common-details__status">{getVehicleStatusWording(config, t)}</div>
          <div className="configurator-common-details__date">{getVehicleDateOrStatus(config, t)}</div>
        </div>
        {getVehicleStatusWording(config, t) === 'a_configurer_ou_contactez_votre_adv' ? (
          <Button type={ButtonType.ALERT} text={t('alert')} />
        ) : null
        }
      </div>

    </animated.div>
  );
}

export default memo(ConfiguratorCommonDetails);