export enum ButtonType {
  PRIMARY,
  SECONDARY,
  TERTIARY,
  ROUNDED,
  ALERT,
  LIGHTSMALL,
  DARKSMALL,
  PRIMARYSMALL,
  CANCEL,
  SAVE,
  EDIT,
  CANCELINLINE,
  DELETE,
  RESET,
  GREYSMALL
}

export default ButtonType;