import { FunctionComponent } from "react";
import { Button, ButtonType } from "_components";
import './discount-validation-modal-content.scss';
import { useTranslation } from "react-i18next";


type DiscountValidationModalContentProps = {
  onClose: () => void;
}


const DiscountValidationModalContent: FunctionComponent<DiscountValidationModalContentProps> = ({ onClose }) => {

  const { t } = useTranslation();
  
  return (
    <div className="discount-validation-modal-content">
      <div className="discount-validation-modal-content__title">{t('notice')}</div>
      <div className="discount-validation-modal-content__content">
        <div className="discount-validation-modal-content__text">{t('discount_confirmation')}</div>
          <Button type={ButtonType.PRIMARY} text="Valider" onClick={onClose}/>
      </div>
    </div>
  );
}

export default DiscountValidationModalContent;