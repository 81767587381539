import { Navigate, Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { atomToken } from '_atoms';
import './connected-page.scss';

const ConnectedPage = () => {
  const [token] = useRecoilState(atomToken);
  const auth = !!token;
  const hash = window.location.hash;


  return auth ? (
    <>
      <div className="connected-page__page-content">
        <Outlet />
      </div>
    </>
  )
  : <Navigate to={"/login"+hash} />;
}

export default ConnectedPage;