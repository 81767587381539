import { useState, FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextInput, TextArea } from "_components";
import './additional-option-item.scss';

export type AdditionalOption = {
  id?: number;
  title: string;
  description: string;
  weight: number | null;
  price: number | null;
};

export type AdditionalOptionItemProps = {
  option: AdditionalOption;
  onUpdate: (option: AdditionalOption) => void;
  onDelete: (option: AdditionalOption) => void;
  isUpdateMode?: boolean;
  isNewOption?: boolean;
};

const AdditionalOptionItem: FunctionComponent<AdditionalOptionItemProps> = ({
  option,
  onUpdate,
  onDelete,
  isUpdateMode = false,
  isNewOption = false
}) => {
  const [updateMode, setUpdateMode] = useState(isUpdateMode);
  const [localOption, setLocalOption] = useState(option);
  const { t } = useTranslation();

  useEffect(() => {
    setLocalOption(option);
  }, [option]);

  const handleUpdate = () => {
    onUpdate(localOption);
    if (!isNewOption) {
      setUpdateMode(false);
    }
  };

  const handleDelete = () => {
    onDelete(localOption);
  }

  const isCreateOptionDisabled = !localOption.title.length || typeof localOption.price !== 'number' || typeof localOption.weight !== 'number' || localOption.weight > 1000;

  return (

    <div className={`additional-option-item ${isNewOption ? 'additional-option-item--new-option' : ''}`}>
      <TextInput
        id={`title-${option.id}`}
        type="text"
        name="title"
        label={t('title')}
        placeholder={t('option_title')}
        value={localOption.title}
        disabled={!updateMode}
        onChange={(e) => setLocalOption({ ...localOption, title: (e.target as HTMLInputElement).value })}
      />
      <TextArea
        id={`description-${option.id}`}
        name={t('description')}
        label="Description"
        placeholder={t('add_description')}
        value={localOption.description}
        disabled={!updateMode}
        onChange={(e) => setLocalOption({ ...localOption, description: (e.target as HTMLTextAreaElement).value })}
      />
      <TextInput
        id={`price-${option.id}`}
        name="price"
        label={t('price_label')}
        type="number"
        value={localOption.price ? localOption.price : ''}
        disabled={!updateMode}
        onChange={(e) => setLocalOption({ ...localOption, price: Number((e.target as HTMLInputElement).value) })}
      /> {t('price_currency')}
      <TextInput
        id={`weight-${option.id}`}
        name="weight"
        label={t('weight_label')}
        type="number"
        value={localOption.weight ? localOption.weight : ''}
        disabled={!updateMode}
        onChange={(e) => setLocalOption({ ...localOption, weight: Number((e.target as HTMLInputElement).value) })}
      /> {t('weight_unit')}
      {updateMode ? (
        <div className="save-btn-container">
          <button
            className="save-btn"
            onClick={handleUpdate}
            disabled={isCreateOptionDisabled}>
            {isNewOption ? t('create_option') : t('validate')}
          </button>
        </div>
      ) : (
        <button className="action-button--update-btn" onClick={() => setUpdateMode(true)}></button>
      )}
      {!isNewOption ? (
        <button className="action-button--delete-btn" onClick={handleDelete}></button>
      ) : null}
    </div>
  );
};

export default AdditionalOptionItem;

