import { FunctionComponent, memo,useEffect, useState } from "react"
import {  useNavigate } from 'react-router-dom';
import './quote-action.scss';
import { Button, ButtonType } from "_components";
import { useTranslation } from 'react-i18next';
import { useModal } from "_hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { atomUser, atomUserLang } from "_atoms";
import { UserResponseType, getUser } from "_api";
import { toast } from "react-toastify";


type QuoteActionProps = {
  setIsOrderConfirmationModalContent : (open : boolean) => void;
  availabilityId : string | undefined;
}

const QuoteAction: FunctionComponent<QuoteActionProps> = (props : QuoteActionProps ) => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useRecoilValue(atomUser);
  const [userData, setUserData] = useState<UserResponseType>();
  const setIsOrderConfirmationModalContent = props.setIsOrderConfirmationModalContent;
  const { setModal } = useModal();

  const handleOrderConfirmationModalContent = () => {
    setModal(true);
    setIsOrderConfirmationModalContent(true);
  };

  const handleUpdateButtonClick = () => {
    navigate('/configurator');
  };

  useEffect(() => {
    if(user){
    getUser(user.id).then((result) => {
      if (result && result.data && result.data.length) {
        setUserData(result.data[0]);
      }
    }, () => {
      toast.error(t('toast_get_data_error'));
    })
    }
  }, []);

  useEffect(() => {
    setModal(true);
  }, []);


  const isAdmin =  userData?.role.name === "Admin-trigano";
  const isDAF =  userData?.role.name=== "DAF";
  const isAgentLimite =  userData?.role.name === "Agent-Limite";
  const isUserTrigano =  userData?.role.name === "User-trigano";

  return (
    <>

    <div className="quote-action">
     <div className="quote-action__wrapper">
     {props.availabilityId !== "1" && props.availabilityId !== "2" && ( 
      <div className="quote-action__button-order">
      <Button type={ButtonType.PRIMARY} text={t("quote_action_button-order")} onClick={handleOrderConfirmationModalContent} 
       disabled={isDAF || isAgentLimite || isUserTrigano || isAdmin}

      />
      </div>
        )}
      <div className="quote-action__button-update">
        <Button type={ButtonType.SECONDARY} text={t("quote_action_button-update")} onClick={handleUpdateButtonClick}
        disabled={isDAF || isAgentLimite || isUserTrigano || isAdmin}
        />
      </div>
      </div>
    </div>
    
    </>

  );
}

export default memo(QuoteAction);