import { FormEvent, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { formatPrice } from "_utils";

import './checkbox.scss';

export type CheckboxProps = {
  // Shared
  name: string;
  id: string;
  title: string;
  price: number;
  weight: number;
  // Native
  checked?: boolean,
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
  // react-hook-form required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
}

const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const { t } = useTranslation();
  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => {
          return (
            <div className="checkbox">
              <input type="checkbox" id={props.id} className={`checkbox__input ${field.value ? "checkbox__input--checked" : ""}`}
                checked={field.value} onChange={field.onChange} />
              <label className="checkbox__form-label">{props.title}
                <span className="checkbox__price">&nbsp;(+ {formatPrice(props.price)}) </span>
                <div className="checkbox__weight">{t('weight')} : {props.weight} {t('weight_measure_unit')}</div>
              </label>
            </div>
          );
        }}
      />
    );
  }
  else {
    return (
      <div className="checkbox">
        <input {...props} type="checkbox" id={props.id} className={`checkbox__input ${props.checked ? "checkbox__input--checked" : ""}`}
          checked={props.checked} onChange={props.onChange} />
        <label className="checkbox__form-label">{props.title}
          <span className="checkbox__price">&nbsp;(+ {formatPrice(props.price)})</span>
          <div className="checkbox__weight">{t('weight')} : {props.weight} {t('weight_measure_unit')}</div>
        </label>
      </div>
    );
  }
}

export default Checkbox;