import { AxiosResponse } from 'axios';
import { AuthenticatedClient,QuoteResponseType, QuoteSaveParamsType, QuoteSaveResponseType, QuoteUrlResponseType} from '_api';
import { QuoteGenerationType } from '_api';
import { ConfigurationIds } from '_types';



/*const quoteUrl : QuoteResponseType = {
 // pdfUrl : 'https://integ-talend.trigano.pro/configurations/createpdf/687138726871381752387125871',
  orno : '123',
}*/

export const saveQuote = (params : QuoteSaveParamsType): Promise<AxiosResponse<QuoteSaveResponseType>> => {      
  return AuthenticatedClient.post('/configurations/save',params);
 /* return new Promise((resolve) => {
    setTimeout(()=> {
      const result = {
      };
      resolve(result);
    }, 1000)
  });*/
};

export const getQuoteUrl = (ORNO : string): Promise<AxiosResponse<QuoteUrlResponseType>> => {
  const API_URL = process.env.REACT_APP_API_URL;
  return AuthenticatedClient.get(API_URL + '/configurations/createpdf/'+ ORNO);
};

export const generatePdfQuote = (atomQuoteValue : QuoteGenerationType, configIds: ConfigurationIds): Promise<AxiosResponse<QuoteResponseType>> => {
  const params = {
   "form" : atomQuoteValue.form,
   "configuration" : {
    ...atomQuoteValue.configuration,
    initialATNR: configIds.initialATNR,
    initialORNO: configIds.initialORNO
   },
  }
  return AuthenticatedClient.post('/configurations/validatedquote/',params);
};

export const quoteToOrder = (quoteValue : QuoteGenerationType, dealerslist: string[]): Promise<AxiosResponse<void>> => {
  /*
  const params = {
   "form" : quoteValue.form,
   "configuration" : quoteValue.configuration,
  }
  console.log('params atomQuoteValueOrder: ', params);
  return AuthenticatedClient.post('/configurations/quotetorder',params);
  */
  const params = {
    "form" : quoteValue.form,
    "configuration" : quoteValue.configuration,
    "dealerslist": dealerslist
   }
   return AuthenticatedClient.post('/configurations/createorder',params);
};

