import { animated } from "@react-spring/web";
import { FunctionComponent, memo, useCallback, useState } from "react";
import { ConfiguratorZoomPhotoViewer } from "_components";
import { useEntrance } from '_hooks';
import './configurator-photo-viewer.scss';

type ConfiguratorPhotoViewerProps = {
  pictures: string[];
}

const ConfiguratorPhotoViewer: FunctionComponent<ConfiguratorPhotoViewerProps> = ({ pictures }) => {
  const [activePictureIndex, setActivePictureIndex] = useState(0);
  const entrance = useEntrance([activePictureIndex]);
  const [zoomed, setZoomed] = useState(false);
  const unZoom = useCallback(() => {
    setZoomed(false);
  }, []);

  return (
    <div className="configurator-photo-viewer">

      <ConfiguratorZoomPhotoViewer picture={pictures[activePictureIndex]} zoomed={zoomed} onClose={unZoom} />

      {pictures.length ? (
        <>
          <animated.div onClick={() => setZoomed(!zoomed)} className="configurator-photo-viewer__main-picture" >
            <img className="configurator-photo-viewer__main-picture-p"  src={pictures[activePictureIndex]}  />
          </animated.div>
          <div className="configurator-photo-viewer__small-pictures-container">
            {pictures.map((picture, index) => (
              <div className="configurator-photo-viewer__small-picture" onClick={() => setActivePictureIndex(index)} style={{ backgroundImage: `url("${picture}")` }} key={index}></div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default memo(ConfiguratorPhotoViewer);
