import React from "react";

import './quote.scss';
import { useRecoilState } from "recoil";
import { atomToken } from "_atoms";


type QuoteProps = {
  quoteUrl: string; 

};

const Quote = ({ quoteUrl }: QuoteProps) => {

  const [token] = useRecoilState(atomToken);

  return (
    <div className="quote" >
      {quoteUrl && quoteUrl.length ?
    <iframe className="quote__quote-pdf" src={`${quoteUrl}&token=${token}`}></iframe>
    : null}
    </div>
  );
}


export default Quote;