import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RecoilRoot } from 'recoil';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "_api";
import TagManager from 'react-gtm-module'

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 */
const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const tagManagerArgs = {
  gtmId: 'GTM-KPLBW87K'
}
TagManager.initialize(tagManagerArgs)  

root.render(
  <MsalProvider instance={msalInstance}>
    <RecoilRoot>
        <BrowserRouter>
          <App />
        </BrowserRouter>
    </RecoilRoot>
  </MsalProvider>
);