import { atom } from 'recoil';
import { QuoteGenerationType } from '_api';
import customStorage from './customStorage';

const key = 'configurationQuote';

const atomQuote = atom<QuoteGenerationType>({
  key,
  default: {
    form: null,
    configuration: null
  },
  effects: customStorage(key)
});

export default atomQuote;
