import { useState, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DiscountValidationModalContent, Modal, TextInput } from "_components";
import { useModal } from "_hooks";
import '../AdditionalOptionItem/additional-option-item.scss';

type DiscountFormOptionType = {
  description: string;
  price: number;
};

type DiscountFormOptionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
};

const DiscountFormOption: FunctionComponent<DiscountFormOptionProps> = ({ control, name }) => {

  const [updateMode, setUpdateMode] = useState(true);
  const { t } = useTranslation();

  const { setModal } = useModal();
  const [discountModal, setDiscountModal] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const [option, setOption] = useState<DiscountFormOptionType>({
          description: field.value?.description,
          price: field.value?.price,
        });

        return (
          <div className="additional-option">
            <div className="additional-option__title">{t('discount')}</div>

            <div className="additional-option-item additional-option__item__discount">
              <TextInput
                id="discount"
                name={t('discount')}
                label="Code"
                type="text"
                placeholder={t('code_seller')}
                value={option.description}
                disabled={!updateMode}
                onChange={(e) => setOption({ ...option, description: (e.target as HTMLTextAreaElement).value })}
              />
              <TextInput
                id="discount-price"
                name="price"
                label={t('price_label') + " - "}
                type="number"
                value={option.price}
                disabled={!updateMode}
                onChange={(e) => setOption({ ...option, price: Number((e.target as HTMLInputElement).value) })}
              /> {t('price_currency')}

              {updateMode ? (
                <div className="save-btn-container">
                  <button
                    className="save-btn"
                    onClick={() => { setModal(true); setDiscountModal(true); }}
                    disabled={typeof option.price !== 'number'}>
                    {t('validate')}
                  </button>
                </div>
              ) : (
                <button className="action-button--update-btn" onClick={() => setUpdateMode(true)}></button>
              )}

              <button className="action-button--delete-btn" onClick={() => { setOption({ description: "", price: 0 }); setUpdateMode(true) }}></button>

            </div>


            {discountModal ? (
              <Modal>
                <DiscountValidationModalContent onClose={() => { setModal(false); field.onChange(option); setUpdateMode(false); setDiscountModal(false) }} />
              </Modal>
            ) : null}

          </div>
        )
      }}
    />
  );
};

export default DiscountFormOption;