import { FunctionComponent } from "react";
import './local-loader.scss';

type LocalLoaderProps = {
  loadingMessage?: string;
}

export const LocalLoader: FunctionComponent<LocalLoaderProps> = ({ loadingMessage }: LocalLoaderProps) => {

  return (
    <div className="local-loader">
      <div className="lds-ring">
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
      </div>
      {loadingMessage ? <p className="loading-message">{loadingMessage}</p> : null}
    </div>
  );
}

export default LocalLoader;