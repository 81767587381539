import { FunctionComponent, memo } from "react";
import "./grey-card-seats.scss";

export type GreyCardSeatsProps = {
  value: number,
  total?: number | null,
  largeVersion  ?: boolean

}

const GreyCardSeats: FunctionComponent<GreyCardSeatsProps> = (props) => {
  return (
    <div className={`grey-card-seats   ${props.largeVersion ? 'grey-card-seats--large' : ''}`}>
      {[...Array(props.value)].map((_, i) => {
        return <span className={`grey-card-seats__icon grey-card-seats__icon--full`} key={i}></span>
      })}
      {typeof props.total == 'number' && props.total > props.value ? [...Array(props.total - props.value)].map((_, i) => {
        return <span className={`grey-card-seats__icon grey-card-seats__icon--empty`} key={i}></span>
      }) : null}
    </div>
  );
}


export default memo(GreyCardSeats);
