import { FunctionComponent, useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { TextInput, CustomSelect, SelectType, TextArea } from "_components";
import { useTranslation } from "react-i18next";
import '../ClientInformations/client-informations.scss';
import { DealerListType } from "_api";

export type ClientInformationsType = {
  dealer: {
    email: string;
    name: string;
    phone: string;
    address: string;
    CUNO : string;
    ADID : string; 
  }
  client: {
    email?: string;
    firstname?: string;
    name?: string;
    phone?: string;
  }
}

type ClientInformationsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  dealerList?: DealerListType[];
};

const ClientInformations: FunctionComponent<ClientInformationsProps> = ({ control, name, dealerList }) => {
  const { t } = useTranslation();

  const [dealershipOptions, setDealershipOptions] = useState<Array<DealerListType>>([]);
  const [dealershipSelectedOption, setDealershipSelectedOption] = useState<DealerListType>();

  if (dealerList) {
    useEffect(() => {
      const dealers = dealerList.map((dealer) => {
        return {
          id: dealer.id,
          label: dealer.name + " - " + dealer.city || "",
          value: dealer.name || "",
          name: dealer.name || "",
          email: dealer.email || "",
          phone: dealer.phone || "",
          address: dealer.address + " , " + dealer.postal_code  + " " + dealer.city  || "",
          postal_code : dealer.postal_code || "",
          city : dealer.city || "",
          CUNO : dealer.CUNO || "",
          ADID : dealer.ADID || ""
        };
      })
      setDealershipOptions(dealers);
    }, []);
  } else { null }


  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const [info, setInfo] = useState<ClientInformationsType>({
          dealer: {
            email: field.value?.dealer.email,
            name: field.value?.dealer.name,
            phone: field.value?.dealer.phone,
            address: field.value?.dealer.address,
            CUNO : field.value?.dealer.CUNO,
            ADID :  field.value?.dealer.ADID,
          },
          client: {
            email: field.value?.client.email,
            firstname: field.value?.client.firstname,
            name: field.value?.client.name,
            phone: field.value?.client.phone
          }
        });

        const setInfoValue = (sub: 'dealer' | 'client', name: string, value: any) => {
          setInfo(prev => ({
            ...prev,
            [sub]: {
              ...prev[sub],
              [name]: value
            }
          }));
        }

        const handleSubmit = () => {
          field.onChange(info);
        };

        const handleSelectChange = (dealer: DealerListType) => {
          setDealershipSelectedOption(dealer);
          setInfoValue('dealer', 'name', dealer.name);
          setInfoValue('dealer', 'email', dealer.email);
          setInfoValue('dealer', 'phone', dealer.phone);
          setInfoValue('dealer', 'address', dealer.address);
          setInfoValue('dealer', 'CUNO', dealer.CUNO);
          setInfoValue('dealer', 'ADID', dealer.ADID);
        }
        useEffect(() => {
          /*
          dealer: {
            email: field.value?.email,
            name: field.value?.name,
            phone: field.value?.phone,
            address: field.value?.address
          },
          client: {
            email: field.value?.email,
            firstname: field.value?.firstname,
            name: field.value?.name,
            phone: field.value?.phone
          }
          setInfoValue('client', 'name', field.value?.email);
          setInfoValue('client', 'name', field.value?.firstname);
          setInfoValue('client', 'name', field.value?.name);
          setInfoValue('client', 'name', field.value?.phone);
          setInfoValue('client', 'name', field.value?.email);
          setInfoValue('client', 'name', field.value?.firstname);
          setInfoValue('client', 'name', field.value?.name);
          setInfoValue('client', 'name', field.value?.phone);
         */
        }, []);

        return (
          <>
            <div className="configurator-form-section">
              <div className="configurator-form-section__group">
                <div className="configurator-form-section__group-label">{t('distributor')} *</div>
                <div className="configurator-form-section__dealer-select">
                  <CustomSelect
                    name="dealerSelect"
                    optionsList={dealershipOptions}
                    placeHolder={t('choose_dealer')}
                    values={dealershipSelectedOption}
                    onChange={handleSelectChange}
                    required={true}
                    type={SelectType.DARKSMALL}
                  />
                  <TextInput id="dealerName" type="text" name={`${name}.dealer.name`} label={t('profile_lastname')} value={info.dealer.name} onChange={(e: any) => { setInfoValue('dealer', 'name', e.target.value) }} />
                  <TextInput id="dealerEmail" type="text" name={`${name}.dealer.email`} label={t('profile_email')} value={info.dealer.email} onChange={(e: any) => { setInfoValue('dealer', 'email', e.target.value) }} />
                  <TextInput id="dealerPhone" type="text" name={`${name}.dealer.phone`} label={t('profile_phone')}   value={info.dealer.phone === "0000000000" ? "" : info.dealer.phone} onChange={(e: any) => { setInfoValue('dealer', 'phone', e.target.value) }} />
                  <TextArea id="dealerAddress" name={`${name}.dealer.address`} label={t('address')} value={info.dealer.address} onChange={(e: any) => { setInfoValue('dealer', 'address', e.target.value) }} />

                  <div className="save-btn-container">
                    <button
                      onClick={handleSubmit}
                      className="save-btn"
                      disabled={!info.dealer.name || !info.dealer.email || !info.dealer.phone || !info.dealer.address}
                    >
                      {t('validate')}
                    </button>
                {/* <a href="#clientInfoSection" className="add-client-btn">{t('add_client')}</a>*/ }
                  </div>
                </div>
              </div>
             {/* <div className="configurator-form-section__group" id="clientInfoSection">
                <div className="configurator-form-section__group-label">{t('client')}</div>
                <TextInput id="clientName" type="text" name={`${name}.client.name`} label={t('profile_lastname') + ' *'} value={info.client.name} onChange={(e: any) => { setInfoValue('client', 'name', e.target.value) }} />
                <TextInput id="clientFirstname" type="text" name={`${name}.client.firstname`} label={t('profile_firstname')} value={info.client.firstname} onChange={(e: any) => { setInfoValue('client', 'firstname', e.target.value) }} />
                <TextInput id="clientEmail" type="text" name={`${name}.client.email`} label={t('profile_email') + ' *'} value={info.client.email} onChange={(e: any) => { setInfoValue('client', 'email', e.target.value) }} />
                <TextInput id="clientPhone" type="text" name={`${name}.client.phone`} label={t('profile_phone')} value={info.client.phone} onChange={(e: any) => { setInfoValue('client', 'phone', e.target.value) }} />
              </div>

              <div className="save-btn-container">
                <button
                  onClick={handleSubmit}
                  className="save-btn"
                  disabled={!info.client.name || !info.client.email}
                >
                  {t('validate')}
                </button>
              </div>
               */}
            </div>
          </>
        );
      }}
    />
  );
};

export default ClientInformations;