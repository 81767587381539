import { useEffect } from 'react';

function useBodyScrollLock(enabled: boolean) {
  useEffect(() => {
    if (enabled) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => document.body.classList.remove('no-scroll');
  }, [enabled]);
}

export default useBodyScrollLock;
