import { FunctionComponent, memo, useState, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { FormValues } from "_types";
import atomConfigIds from "_atoms/atomConfigIds";
import { toast } from "react-toastify";
import {
  ConfigurationFormSingleSelectionItem,
  ConfigurationFormSingleSelectionWithImgItem,
  ConfiguratorClientInfos,
  ConfiguratorDistributorOptions,
  ConfiguratorForm,
  ConfiguratorFormDescriptionTab,
  ConfiguratorFormMultipleSelection,
  ConfiguratorFormMultipleSelectionCheckbox,
  ConfiguratorFormMultipleSelectionItem,
  ConfiguratorFormSingleSelectionGroup,
  ConfiguratorFormSingleSelectionTab,
  ConfiguratorFormSingleSelectionWithImgGroup,
  ConfiguratorFormSingleSelectionWithImgTab,
  getPTACOptions,
  PTACResponseType,
  DealerListType,
  ConfiguratorConfig,
  InfoClient
} from "_api";
import { useRecoilState, useSetRecoilState } from "recoil";
import { AbsoluteLoader, ConfiguratorFormSection, ConfiguratorWeightModalContent, Modal, ConfiguratorWeightModalContentCaravan, ConfiguratorButtons, ConfiguratorClientModalContent } from "_components";
import { useTranslation } from "react-i18next";
import { useLoading, useModal } from "_hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { debounce, get } from "lodash";
import { AxiosResponse } from "axios";
import "./configurator-accordion.scss";
import { useNavigate } from "react-router-dom";

const UPDATE_DELAY = 0; // ms


type ConfiguratorAccordionProps = {
  form: ConfiguratorForm;
  config: ConfiguratorConfig;
  onChange: (lastModifiedValue: FormData | unknown, name: string) => void;
  loading: boolean;
  vehicle_type: "1" | "2";
  overweight?: boolean;
  gvw: number | null | undefined;
  onPTACChange: (id: string, ptac: string, ATID: string) => void;
  onCGChange: () => void;
  onRollback: () => void;
  dealerList: DealerListType[];
  onSubmit?: () => void,
  timeStamp: string,
  onClientChange : (clientInfo : InfoClient) => void;
  onCreateQuote :() => void;
};

const ConfiguratorAccordion: FunctionComponent<ConfiguratorAccordionProps> = (props: ConfiguratorAccordionProps) => {
  const { t } = useTranslation();
  const [modalContentType, setModalContentType] = useState<'overweight-motorhome' | 'overweight-caravan' | null>(null);
  const [ptac, setPtac] = useState<PTACResponseType>({
    Masse: [],
    configuration: null,
    place_CG: "",
    ATID: "",
    dealerList: []
  });
  const isCampingCar = props.vehicle_type === "1";
  const isCaravan = props.vehicle_type === "2";
  const [configIds] = useRecoilState(atomConfigIds);
  const [isClientInfoModal, SetIsClientInfoModal] = useState(false);
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const [clientInfo, setClientInfo] = useState<InfoClient>({
  
    firstName: '',
    lastName: '',
    clientPhone: '',
    clientEmail: ''
  });

  const [activeSection, setActiveSection] = useState('');
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    clientInfos: Yup.object().shape({
      dealer: Yup.object().shape({
        name: Yup.string().required(t('required_field')),
        email: Yup.string().required(t('required_field')).email(t('invalid_format')),
        phone: Yup.string().required(t('required_field')).matches(phoneRegExp, t('invalid_format')).min(6),
        CUNO: Yup.string().required(t('required_field')),
        ADID: Yup.string().required(t('required_field'))
      }),
      client: Yup.object().shape({
        email: Yup.string().email(t('invalid_format')),
        phone: Yup.string().matches(phoneRegExp, t('invalid_format')).min(6)
      }),
    }),
  });

  // Initial  PTAC loading.
  const loadPTAC = () => {
    getPTACOptions(configIds).then((result: AxiosResponse<PTACResponseType>) => {
      setPtac({
        configuration: result.data.configuration,
        Masse: result.data.Masse,
        place_CG: result.data.place_CG,
        ATID: result.data.ATID,
        dealerList: result.data.dealerList
      });
    }, (error) => {
      toast.error(t('toast_get_data_error'));
      console.error('error: ', error);
      setPtac({
        Masse: [],
        configuration: null,
        place_CG: "",
        ATID: "",
        dealerList: []

      });
    });
  };

  // Initial data loading.
  useLayoutEffect(() => {
    loadPTAC();
  }, []);

  const getDefaultValues = () => {

    const defaultValues: FormValues = {
      carrier: {},
      finish: {},
      pack: {},
      carrierOption: {},
      cellOption: {},
      clientInfos: {
        dealer: {
          name: (props.form.clientInfos as ConfiguratorClientInfos).dealer.name || '',
          email: (props.form.clientInfos as ConfiguratorClientInfos).dealer.email || '',
          phone: (props.form.clientInfos as ConfiguratorClientInfos).dealer.phone || '',
          address: (props.form.clientInfos as ConfiguratorClientInfos).dealer.phone || '',
          CUNO : (props.form.clientInfos as ConfiguratorClientInfos).dealer.CUNO || '',
          ADID: (props.form.clientInfos as ConfiguratorClientInfos).dealer.ADID || ''
        },
        client: {
          name: (props.form.clientInfos as ConfiguratorClientInfos).client.name || '',
          firstname: (props.form.clientInfos as ConfiguratorClientInfos).client.firstname || '',
          email: (props.form.clientInfos as ConfiguratorClientInfos).client.email || '',
          phone: (props.form.clientInfos as ConfiguratorClientInfos).client.phone || ''
        }
      },
      distributorOptions: {
        additionalOptions: (props.form.distributorOptions as ConfiguratorDistributorOptions).additionalOptions,
        takeOver: {
          id: (props.form.distributorOptions as ConfiguratorDistributorOptions).takeOver.id,
          description: (props.form.distributorOptions as ConfiguratorDistributorOptions).takeOver.description || '',
          price: (props.form.distributorOptions as ConfiguratorDistributorOptions).takeOver.price
        },
        startUpCosts: {
          id: (props.form.distributorOptions as ConfiguratorDistributorOptions).startUpCosts.id,
          //price: (props.form.distributorOptions as ConfiguratorDistributorOptions).startUpCosts.price || 0
          startupPrice: (props.form.distributorOptions as ConfiguratorDistributorOptions).startUpCosts.startupPrice,
          greycardPrice: (props.form.distributorOptions as ConfiguratorDistributorOptions).startUpCosts.greycardPrice
        },
        discount: {
          id: (props.form.distributorOptions as ConfiguratorDistributorOptions).discount.id,
          description: (props.form.distributorOptions as ConfiguratorDistributorOptions).discount.description,
          price: (props.form.distributorOptions as ConfiguratorDistributorOptions).discount.price
        },
        commentary: {
          id: (props.form.distributorOptions as ConfiguratorDistributorOptions).commentary.id,
          description: (props.form.distributorOptions as ConfiguratorDistributorOptions).commentary.description || ''
        }
      }
    };

    if (isCampingCar) {
      (props.form.carrier as ConfiguratorFormSingleSelectionTab).groups.forEach((group: ConfiguratorFormSingleSelectionGroup) => {
        const selectedValue = group.items.find((item: ConfigurationFormSingleSelectionItem) => item.selected);

        if (selectedValue) {
          defaultValues.carrier[group.name] = {
            _id: selectedValue.id,
            id: selectedValue.value,
            label: selectedValue.title,
            price: selectedValue.price,
            value: selectedValue.value,
            disabled: selectedValue.disabled,
            picture: selectedValue.value,
          }
        }
      });
    }

    (props.form.finish as ConfiguratorFormSingleSelectionWithImgTab).groups.forEach((group: ConfiguratorFormSingleSelectionWithImgGroup) => {
      const selectedValue = group.items.find((item: ConfigurationFormSingleSelectionWithImgItem) => item.selected);
      if (selectedValue) {
        defaultValues.finish[group.name] = {
          _id: selectedValue.id,
          id: selectedValue.value,
          label: selectedValue.title,
          price: selectedValue.price,
          value: selectedValue.value,
          picture: selectedValue.img,
          disabled: selectedValue.disabled,
        }
      }
    });


    (props.form.pack as ConfiguratorFormMultipleSelection).items.forEach((item: ConfiguratorFormMultipleSelectionItem) => {
      defaultValues.pack[item.value] = item.selected
    });



    // (props.form.pack as ConfiguratorFormSingleSelectionTab).groups.forEach((group: ConfiguratorFormSingleSelectionGroup) => {
    //   const selectedValue = group.items.find((item: ConfigurationFormSingleSelectionItem) => item.selected);
    //   if (selectedValue) {
    //     defaultValues.carrierOption[group.name] = {
    //       id: selectedValue.value,
    //       label: selectedValue.title,
    //       price: selectedValue.price,
    //       value: selectedValue.value,
    //       disabled : selectedValue.disabled,
    //       picture: selectedValue.value
    //     }
    //   }
    // });

    if (isCampingCar) {
      (props.form.carrierOption as ConfiguratorFormSingleSelectionTab).groups.forEach((group: ConfiguratorFormSingleSelectionGroup) => {
        const selectedValue = group.items.find((item: ConfigurationFormSingleSelectionItem) => item.selected);
        if (selectedValue) {
          defaultValues.carrierOption[group.name] = {
            _id: selectedValue.id,
            id: selectedValue.value,
            label: selectedValue.title,
            price: selectedValue.price,
            value: selectedValue.value,
            disabled: selectedValue.disabled,
            picture: selectedValue.value
          }
        }
      });
    }

    /*
    (props.form.cellOption as ConfiguratorFormMultipleSelectionCheckbox).items.forEach((item: ConfiguratorFormMultipleSelectionCheckboxItem) => {
      defaultValues.cellOption[item.value] = item.selected;
    });
    */

    (props.form.cellOption as ConfiguratorFormSingleSelectionTab).groups.forEach((group: ConfiguratorFormSingleSelectionGroup) => {
      const selectedValue = group.items.find((item: ConfigurationFormSingleSelectionItem) => item.selected);
      if (selectedValue) {
        defaultValues.cellOption[group.name] = {
          _id: selectedValue.id,
          id: selectedValue.value,
          label: selectedValue.title,
          price: selectedValue.price,
          value: selectedValue.value,
          disabled: selectedValue.disabled,
          picture: selectedValue.img
        }
      }
    });

    return defaultValues;
  };

  const { setModal } = useModal();

  const {
    control,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: getDefaultValues(),
    resolver: yupResolver(validationSchema)
  });

  const isFormLoaded = useRef(false);
  const lastName = useRef('');
  const isRollback = useRef(false);

  const toggleVisibleSection = (section: string) => {
    setActiveSection(prevActiveSection => prevActiveSection === section ? '' : section);
  };

  const isSectionVisible = useCallback((section: string) => {
    return activeSection === section;
  }, [activeSection]);

  const updateDataDebounced = useCallback(debounce((lastModifiedValue: FormData | unknown, name: string) => {
    props.onChange(lastModifiedValue, name);
    isFormLoaded.current = false;
  }, UPDATE_DELAY), [props.onChange]);

  useEffect(() => {

    const { unsubscribe } = watch((data, { name }) => {
      if ((name || (isRollback.current && lastName.current)) && isFormLoaded.current) {
        const _name = name ? name : lastName.current;
        lastName.current = _name;
        isRollback.current = false;
        const nameParts = (_name as string).split('.');
        let lastModifiedValue: FormData | unknown = data;
        if (nameParts[0] === 'distributorOptions') {
          lastModifiedValue = get(lastModifiedValue, 'distributorOptions');
          updateDataDebounced(lastModifiedValue, _name);
        }
        else if (nameParts[0] === 'pack') {
          nameParts.forEach((part) => {
            lastModifiedValue = get(lastModifiedValue as Record<string, unknown>, part);
          });
          updateDataDebounced(lastModifiedValue, _name);
        }
        else {
          nameParts.forEach((part) => {
            lastModifiedValue = get(lastModifiedValue as Record<string, unknown>, part);
          });
          updateDataDebounced(lastModifiedValue, _name);
        }
      }

    });
    return () => {
      unsubscribe();
    }

  }, [watch, isFormLoaded]);

  useEffect(() => {

    if (props.form) {
      isFormLoaded.current = true;
      reset(getDefaultValues());
    }
  }, [props.form]);

  const [isWeightCampingCarModalOpen, setWeightCampingCarModalOpen] = useState(false);
  const [isWeightCaravanModalOpen, setWeightCaravanModalOpen] = useState(false);


  useEffect(() => {
    if (props.overweight && (isCampingCar || isCaravan)) {
      if (isCampingCar) {
        setWeightCaravanModalOpen(false);
        setWeightCampingCarModalOpen(true);
      } else {
        setWeightCampingCarModalOpen(false);
        setWeightCaravanModalOpen(true);
      }
      setModal(true);
    }

  }, [props.timeStamp, props.overweight, props.config, setModal]);

  
  const handleCloseModals = () => {
    setWeightCampingCarModalOpen(false);
    setWeightCaravanModalOpen(false);
  }

  useEffect(() => {
    if (props.overweight) {
      setModalContentType(isCampingCar ? 'overweight-motorhome' : 'overweight-caravan');
      setModal(true);
    }
  }, [props.timeStamp, props.overweight,setModalContentType, props.form]);

  useEffect(() => {
    setModal(modalContentType !== null);
  }, [modalContentType, setModal]);


  const handlCreateQuoteClick = () => {
    props.onCreateQuote();
  };


  const handleOpenClientInfoModal = ( ) => {
      setModal(true)
      SetIsClientInfoModal(true);
  
  };

  const handleClientInfoChange = (client: InfoClient) => {

    setLoading(true)
    setClientInfo(client);
    props.onClientChange(client);
    setLoading(false)
  };

  return (
    <>
      {/* Weight modal */}
      {isWeightCampingCarModalOpen && (
        <Modal>
          <ConfiguratorWeightModalContent configuration={props.config} weightOptions={ptac} gvw={props.gvw} onClose={handleCloseModals} onPTACChange={props.onPTACChange} ATID={ptac.ATID} onCGChange={props.onCGChange} onRollback={() => { isRollback.current = true; props.onRollback() }} />
        </Modal>
      )}
      {isWeightCaravanModalOpen && (
        <Modal>
          <ConfiguratorWeightModalContentCaravan onClose={handleCloseModals} />
        </Modal>
      )}

      {/* Accordion menu */}
      <div className="configurator-accordion">

        {props.loading ? (
          <AbsoluteLoader />
        ) : null}

        <div className={`configurator-accordion__form-section ${!isSectionVisible('model') ? 'configurator-accordion__form-section--hidden' : ''}`}>
          <button className="configurator-accordion__form-section-header" onClick={() => toggleVisibleSection('model')}>
            <span className="configurator-accordion__form-section-title">{t('configurator_model')}</span>
          </button>
          <div className="configurator-accordion__form-section-content">
            <ConfiguratorFormSection  availability={ props.config.availability} scom={ props.config.SCOM || ''} sectionName="model" section={props.form.model as ConfiguratorFormDescriptionTab} control={control} />
          </div>
        </div>

        {isCampingCar && (
          <>
            <div className={`configurator-accordion__form-section  ${!isSectionVisible('carrier') ? 'configurator-accordion__form-section--hidden' : ''}`}>
              <button className="configurator-accordion__form-section-header" onClick={() => toggleVisibleSection('carrier')}>
                <span className="configurator-accordion__form-section-title">{t('configurator_carrier')}</span>
              </button>
              <div className="configurator-accordion__form-section-content">
                <ConfiguratorFormSection availability={ props.config.availability}  scom={ props.config.SCOM || ''} sectionName="carrier" section={props.form.carrier as ConfiguratorFormSingleSelectionTab} control={control} />
              </div>
            </div>
          </>
        )}
        <div className={`configurator-accordion__form-section ${!isSectionVisible('finish')  ? 'configurator-accordion__form-section--hidden' : ''}`}>
          <button className="configurator-accordion__form-section-header" onClick={() => toggleVisibleSection('finish')}>
            <span className="configurator-accordion__form-section-title">{t('configurator_finish')}</span>
          </button>
          <div className="configurator-accordion__form-section-content">
            <ConfiguratorFormSection availability={ props.config.availability} scom={ props.config.SCOM || ''} sectionName="finish" section={props.form.finish as ConfiguratorFormSingleSelectionWithImgTab} control={control} />
          </div>
        </div>

        <div className={`configurator-accordion__form-section ${!isSectionVisible('pack') ? 'configurator-accordion__form-section--hidden' : ''}`}>
          <button className="configurator-accordion__form-section-header" onClick={() => toggleVisibleSection('pack')}>
            <span className="configurator-accordion__form-section-title">{t('configurator_pack')}</span>
          </button>
          <div className="configurator-accordion__form-section-content">
            <ConfiguratorFormSection availability={ props.config.availability}  scom={ props.config.SCOM || ''} sectionName="pack" section={props.form.pack as ConfiguratorFormMultipleSelection} control={control} />
          </div>
        </div>

        {isCampingCar && (
          <>
            <div className={`configurator-accordion__form-section ${!isSectionVisible('carrierOption') ? 'configurator-accordion__form-section--hidden' : ''}`}>
              <button className="configurator-accordion__form-section-header" onClick={() => toggleVisibleSection('carrierOption')}>
                <span className="configurator-accordion__form-section-title">{t('configurator_carrier_option')}</span>
              </button>
              <div className="configurator-accordion__form-section-content">
                <ConfiguratorFormSection  availability={ props.config.availability}  scom={ props.config.SCOM || ''} sectionName="carrierOption" section={props.form.carrierOption as ConfiguratorFormSingleSelectionTab} control={control} />
              </div>
            </div>
          </>
        )}
        <div className={`configurator-accordion__form-section ${!isSectionVisible('cellOption')  ? 'configurator-accordion__form-section--hidden' : ''}`}>
          <button className="configurator-accordion__form-section-header" onClick={() => toggleVisibleSection('cellOption')}>
            <span className="configurator-accordion__form-section-title">{t('configurator_cell_option')}</span>
          </button>
          <div className="configurator-accordion__form-section-content">
            <ConfiguratorFormSection availability={ props.config.availability} scom={ props.config.SCOM || ''}  sectionName="cellOption" section={props.form.cellOption as ConfiguratorFormMultipleSelectionCheckbox} control={control} />
          </div>
        </div>

        <div className={`configurator-accordion__form-section ${!isSectionVisible('distributorOption') ? 'configurator-accordion__form-section--hidden' : ''}`}>
          <button className="configurator-accordion__form-section-header" onClick={() => toggleVisibleSection('distributorOption')}>
            <span className="configurator-accordion__form-section-title">{t('configurator_distributor_option')}</span>
          </button>
          <div className="configurator-accordion__form-section-content">
            <ConfiguratorFormSection  availability={ props.config.availability}  scom={ props.config.SCOM || ''} sectionName="distributorOptions" section={props.form.distributorOptions as ConfiguratorDistributorOptions} control={control} />
          </div>
        </div>
        <div className={`configurator-accordion__form-section ${!isSectionVisible('clientInfos') ? 'configurator-accordion__form-section--hidden' : ''}`}>
          <button className="configurator-accordion__form-section-header" onClick={() => toggleVisibleSection('clientInfos')}>
            <span className="configurator-accordion__form-section-title">{t('point_of_sale')}</span>
          </button>
          <div className="configurator-accordion__form-section-content">
            <ConfiguratorFormSection availability={ props.config.availability}  scom={ props.config.SCOM || ''} sectionName="clientInfos" section={props.form.clientInfos as ConfiguratorClientInfos} control={control} dealerList={props.dealerList} />
          </div>
        </div>
      </div>
      <ConfiguratorButtons setClientInfoModal={handleOpenClientInfoModal}  form={props.form} />
       {isClientInfoModal && (
          <Modal
            content={true}
            oncancel={() => SetIsClientInfoModal(false)}
            onValidate={() => SetIsClientInfoModal(false)}
          >
            <ConfiguratorClientModalContent title={t('add_info_client')} name="clientInfo" onClose={() => SetIsClientInfoModal(false)}  onClientChange={handleClientInfoChange} onCreateQuoteClick={handlCreateQuoteClick} form={props.form}/>
          </Modal>
        )}
    </>
  );
};

export default memo(ConfiguratorAccordion);