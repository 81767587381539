import {atom} from 'recoil';
import customStorage from './customStorage';
import { User } from '_types';
import { UserResponseType } from '_api';

const key = 'userlang';

const atomUserLang = atom<UserResponseType | null>({
  key,
  default: null,
  effects: customStorage(key),
});

export default atomUserLang;
