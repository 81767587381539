import { FunctionComponent } from "react";
import './absolute-loader.scss';

const AbsoluteLoader: FunctionComponent = () => {

  return (
    <div className="absolute-loader">
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  );
}

export default AbsoluteLoader;