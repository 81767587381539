import { FunctionComponent, useEffect, useState } from "react";
import {
  Quote,
  QuotePrint,
  QuoteAction,
  Modal,
  OrderConfirmationModalContent,
  PageTitle,
} from "_components";
import "./quote-page.scss";
import { animated } from "@react-spring/web";
import { useConfiguratorEntrance, useLoading } from "_hooks";
import { ConfiguratorClientInfos, QuoteResponseType, generatePdfQuote, getQuoteUrl, saveQuote } from "_api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import atomConfigIds from "_atoms/atomConfigIds";
import atomQuote from "_atoms/atomQuote";
import { AxiosResponse } from "axios";
import { atomToken, atomUser, atomUserLang } from "_atoms";

type QuoteData = {
  periodOfValidity: string;
  quoteReference: string;
}
const quotePage: FunctionComponent = () => {
  const entrance = useConfiguratorEntrance();
  const [isOrderConfirmationModalContent, setIsOrderConfirmationModalContent] =useState(false);
  const [orno, setOrno] = useState<string>("");
  const { setLoading } = useLoading();
  const { t } = useTranslation();
  const [configIds, setConfigIds] = useRecoilState(atomConfigIds);
  const [atomQuoteValue, setAtomQuoteValue] = useRecoilState(atomQuote);
  const [quoteUrl, setQuoteUrl] = useState<string>("");
  const [token] = useRecoilState(atomToken);
  const timesTemp = Date.now();
  const [user] = useRecoilState(atomUser);
  const userLang = useRecoilValue(atomUserLang);



  const API_URL = process.env.REACT_APP_API_URL;


  const initData = () => {
    setLoading(true);
    setQuoteUrl("");
    generatePdfQuote(atomQuoteValue, configIds)
      .then((quoteInvoiResult: AxiosResponse<QuoteResponseType>) => {
        const ornoResult = quoteInvoiResult.data.response.ORNO;

        if (atomQuoteValue.configuration) {
          let lang = userLang?.language ?? null; 

          const newAtom = {
            form: atomQuoteValue.form,
            configuration: {
              ...atomQuoteValue.configuration,
              lang : lang,
              newATNR: quoteInvoiResult.data.response.newATNR ?? null,
              newORNO: quoteInvoiResult.data.response.newORNO ?? null,
            },
          };
          setAtomQuoteValue(newAtom);
          setConfigIds((prev) => {
            return {
              ...prev,
              lang :  lang,
              newAtnr: quoteInvoiResult.data.response.newATNR ?? null,
              newOrno: quoteInvoiResult.data.response.newORNO ?? null,
            };
          });
        }
        setOrno(ornoResult);
        const pdfUrl = API_URL + `/configurations/createpdf/${ornoResult}?date=${timesTemp}&token=${token}`;
        setQuoteUrl(pdfUrl);
        setLoading(false);
      fetch(pdfUrl, {
        headers: new Headers({
          Access_token: token || ""
        })
      })
      .then(response => {
      })
      .catch(error => {
        toast.error("Erreur lors de la récupération du PDF ");
      });
      })
      .catch((error) => {
        console.log("error: ", error);
        setLoading(false);
        toast.error("Une erreur est survenue lors du chargement des données");
      });
  };
  useEffect(() => {
    initData();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleOpenOrderConfirmationModalContent = () => {
    setIsOrderConfirmationModalContent((prevIsModalOpen) => !prevIsModalOpen);
  };

  const handleCloseModals = () => {
    setIsOrderConfirmationModalContent(false);
  };
 
  const handleQuoteDataChange = (data: QuoteData) => {
    const dealerInfo = (atomQuoteValue?.form?.clientInfos as ConfiguratorClientInfos)?.dealer;

    const params = {
      ORNO: orno,
      dureeValidite: data.periodOfValidity,
      devisReference: data.quoteReference,
      infoDealer:  {
        ADID: dealerInfo.ADID,
        CUNO: dealerInfo.CUNO,
        address: dealerInfo.address,
        email: dealerInfo.email,
        name: dealerInfo.name,
        phone: dealerInfo.phone
      } 
    };
    saveQuote(params).then(
      (result) => {
        setLoading(true);
        const pdfUrl = API_URL + `/configurations/createpdf/${orno}?date=${timesTemp}`;
        setQuoteUrl(pdfUrl);
        toast.success(t("toast_save_success"));
       setLoading(false);
      },
      (error) => {
        toast.error(t("toast_save_quote_error"));
        setLoading(false);
      }
    );
  };

  return (
    <>
      <PageTitle
        title={t("quote_page_title")}
        description={t("quote_page_description")}
      />

      <div className="quote-page">
        <div className="quote-page__quote-wrapper">
          <animated.div className="quote" style={entrance}>
            <div className="quote-page__body">
              {/* Left part: devis pdf*/}
              <div className="quote-page__quote-pdf">
                <Quote key={timesTemp}  quoteUrl={quoteUrl} />
              </div>
              {/* Right part: bloc sauvegarde et boutons d'action */}
              <div className="quote-page__quote-wrapper-right">
                <div className="quote-page__quote-wrapper-save">
                  <QuotePrint setQuoteData={handleQuoteDataChange} />
                  {/* Render the modal if isModalOpen is true 
                  {isSaveQuoteModal && (
                    <Modal>
                      <SaveQuoteModalContent quoteReference={onQuoteReferenceChange} onClose={() => setIsSaveQuoteModal(false)} />
                    </Modal>
                  )}*/}
                </div>
                <div className="quote-page__quote-action-bouton">
                  <QuoteAction
                    setIsOrderConfirmationModalContent={handleOpenOrderConfirmationModalContent}
                    availabilityId={atomQuoteValue.configuration?.availability_id}

                  />
                  {/* Render the modal if isModalOpen is true */}
                  {isOrderConfirmationModalContent && (
                    <Modal>
                      <OrderConfirmationModalContent
                        onClose={handleCloseModals}
                      />
                    </Modal>
                  )}
                </div>
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
};

export default quotePage;
