import { animated } from "@react-spring/web";
import { FunctionComponent, memo, useRef, useState } from "react";
import { ConfiguratorAccordion, ConfiguratorCommonDetails, ConfiguratorPhotoViewer, ConfiguratorTechnicalDetails } from "_components";
import { useConfiguratorEntrance } from "_hooks";
import { ConfiguratorResponseType, InfoClient } from '_api';
import './configurator.scss';

type ConfiguratorProps = {
  configuration: ConfiguratorResponseType,
  onFormChange: (lastModifiedValue: FormData | unknown, name: string) => void;
  onPTACChange: (id: string, ptac: string, ATID: string) => void;
  onCGChange: () => void;
  onRollback: () => void;
  onClientChange : (clientInfo : InfoClient) => void;
  onCreateQuote : () => void;
}

const Configurator: FunctionComponent<ConfiguratorProps> = ({ configuration, onFormChange, onPTACChange, onCGChange, onRollback,onClientChange ,onCreateQuote}) => {
  const entrance = useConfiguratorEntrance();
  const technicalDetailsRef = useRef(null);

  const handleClientInfoChange = (client: InfoClient) => {
    onClientChange(client);
  };

  const handlCreateQuote = () => {
    onCreateQuote();
  };
  
  return (
    <animated.div className="configurator" style={entrance}>

      {/* header */}
      <div className="configurator__header">

        {/* Left part: configurator common details */}
        <div className="configurator__common-details-wrapper">
          {configuration.configuration ? (
            <ConfiguratorCommonDetails config={configuration.configuration} />
          ) : null}
        </div>
        {/* Right part: configurator technical details (desktop) */}
        <div className="configurator__technical-details-wrapper" ref={technicalDetailsRef}>
          {configuration.configuration ? (
            <ConfiguratorTechnicalDetails config={configuration.configuration} />
          ) : null}
        </div>

        {/* Responsive technical details  
        <ConfiguratorResponsiveTechnicalDetails technicalDetailsRef={technicalDetailsRef} price={configuration.configuration?.price_vehicle || 0} seatsNumber={configuration.configuration?.seats_number || 0} />
        */}

      </div>

      {/* body */}
      <div className="configurator__body">

        {/* Left part: photo viewer */}
        <div className="configurator__photo-viewer-wrapper">
          {configuration.pictures ? (
            <ConfiguratorPhotoViewer pictures={configuration.pictures} />
          ) : null}
        </div>

        {/* Right part: accordion */}
        <div className="configurator__accordion-wrapper">
          {configuration.form && configuration.configuration ? (
            <ConfiguratorAccordion config={configuration.configuration} vehicle_type={(configuration.configuration.vehicle_type as "1" || "2") || "1"} form={configuration.form} onChange={onFormChange} loading={!configuration.updateLoaded} timeStamp= {configuration.timeStamp}  overweight={configuration.overweight}
              gvw={configuration.configuration?.gvwd} onPTACChange={onPTACChange} onCGChange={onCGChange} onRollback={onRollback} dealerList={configuration.dealerList} onClientChange={handleClientInfoChange} onCreateQuote={handlCreateQuote}/>
          ) : null}
        </div>

      </div>

    </animated.div >
  );
}

export default memo(Configurator);