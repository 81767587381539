import { FormEvent, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import { formatPrice } from "_utils";
import './radio.scss';

export type Option = {
  id: string,
  values : string,
  price : number,
  label : string,
  isSelected : boolean,
}

export type RadioProps = {
  // Shared
  options: Array<Option>,
  name: string,
  label : string,
  // Native
  price: number;
  value?: string|null,
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
  // react-hook-form required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
}

const Radio: FunctionComponent<RadioProps> = (props) => {
  if (props.control) {
    return (
      <Controller
      control={props.control}
      name={props.name}
      render={({field}) => {
        return (
          <div className="radio" {...props}>
            {props.options.map((option) => {
              return (
                <div key={option.id} className="radio__option-container">
                  <input type="radio" id={option.id} value={option.values} name={props.name} onChange={() => field.onChange(option.values)} defaultChecked={field.value === option.values} className="radio__option-input"/>
                      <label htmlFor={option.id} className="radio__option-label">
                      {option.label}
                      {option.price && (
                        <span className="radio__price"> (+ {formatPrice(option.price) })</span>
                      )}
                    </label>
                </div>
              )
            })}
          </div>
        );
      }}
    />
    );
  }
  else {
    return (
      <div className="radio" {...props}>
        {props.options.map((option) => {
          return (
            <div key={option.id} className="radio__option-container">
              <input type="radio" id={option.id} value={option.values} name={props.name} onChange={props.onChange} defaultChecked={props.value === option.values} className="radio__option-input"/>
              <label htmlFor={option.id} className="radio__option-label">{option.label}
              <span className="radio__price"> (+ {props.price})</span>
             </label>
            </div>
          )
        })}
      </div>
    );
  }
}


export default Radio;