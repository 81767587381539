import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { formatPrice } from "_utils";
import './horizontal-finition-option-value.scss';

export type FinitionOptionValueType = {
  _id?: number,
  id: string,
  label: string,
  price: number
  value: string,
  picture: string,
  disabled?: boolean;
}

export type HorizontalFinitionOptionValueProps = {
  // Shared
  options: Array<FinitionOptionValueType>,
  name: string,
  label: string,
  // react-hook-form required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
}

const HorizontalFinitionOptionValue: FunctionComponent<HorizontalFinitionOptionValueProps> = (props) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => {

        const [value, setValue] = useState<FinitionOptionValueType>(field.value)

        const onButtonClick = (option: FinitionOptionValueType) => {
          setValue(option)
        }

        useEffect(() => {
          field.onChange(value)
        }, [value])

        return (
          <div className="horizontal-finition-option-value" {...props}>
            <div className="horizontal-finition-option-value__title">{props.label}</div>
            <div className="horizontal-finition-option-value__item">
              {props.options.map((option: FinitionOptionValueType) => {
                const priceValue = option.price ? `(+ ${formatPrice(option.price)})` : `(${formatPrice(0)})`;
                return (
                  <button key={option.id} className={`horizontal-finition-option-value__button ${value && option.value === value.value ? 'horizontal-finition-option-value__button--active' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      onButtonClick(option)
                    }}
                    disabled={option.disabled}
                  >
                    <div className="horizontal-finition-option-value__image" style={{ backgroundImage: `url("${option.picture}")` }}></div>
                    <div className="horizontal-finition-option-value__content">
                      <div className="horizontal-finition-option-value__text">
                        <div className="horizontal-finition-option-value__label">{option.label}</div>
                        <div className="horizontal-finition-option-value__sublabel">{priceValue}</div>
                      </div>
                    </div>
                  </button>
                )
              })}
            </div>
          </div >
        );
      }}
    />
  );
}

export default HorizontalFinitionOptionValue;