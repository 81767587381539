import { AxiosResponse } from 'axios';
import { DealershipResponseType, AuthenticatedClient } from '_api';

export const getDealerships = (): Promise<AxiosResponse<DealershipResponseType[]>> => {
  return AuthenticatedClient.get('/dealers');
};

export const getDealershipsById = (email: string): Promise<AxiosResponse<DealershipResponseType[]>> => {
  return AuthenticatedClient.get('/dealersdetailed/' + email);
};
