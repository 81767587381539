import { useSpring } from '@react-spring/web';
import { useCallback } from 'react';

export function useConfiguratorEntrance() {
  const entrance = useCallback(
    () =>
      useSpring({
        from: { opacity: 0, transform: 'translateY(20px) scale(0.95)' },
        to: { opacity: 1, transform: 'translateY(0) scale(1)' },
        config: { tension: 200, friction: 12, mass: 1 }
      }),
    []
  );
  return entrance();
}
