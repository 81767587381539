import { FunctionComponent, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "_api";
import { useSetRecoilState } from "recoil";
import { atomToken, atomUser } from "_atoms";
import { useLoading } from "_hooks";
import { User } from "_types";
import atomConfigIds from "_atoms/atomConfigIds";

const LoginPage: FunctionComponent = () => {
  const { instance } = useMsal();
  const setToken = useSetRecoilState(atomToken);
  const setUser = useSetRecoilState(atomUser);
  const setConfigIds = useSetRecoilState(atomConfigIds);
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const getUrlParams = () => {
    const params = new URLSearchParams(window.location.search);

    const object = {
      vehicle_id: params.get('vehicle_id') || '',
      cuno: params.get('cuno')?.trim() || '',
      faci: params.get('faci')?.trim() || '',
      whlo: params.get('whlo')?.trim() || '',
      itno: params.get('itno')?.trim() || '',
      danr: params.get('danr')?.trim() || '',
      oref: params.get('oref')?.trim() || '',
      dealer: params.get('dealer')?.trim() || null,
      atav: params.get('atav')?.trim() || '',
      atid: params.get('atid')?.trim() || '',
      atnr: params.get('atnr')?.trim() || '',
      orno: params.get('orno')?.trim() || null,
      slot: params.get('slot')?.trim() || null,
      scom: params.get('scom')?.trim() || '',
      country: params.get('countries')?.trim() || '',
      dead_line_production_date: params.get('dead_line_production_date')?.trim() || '',
      stat: params.get('stat')?.trim() || '',
      stas: params.get('stas')?.trim() || '',
      fidt: params.get('fidt')?.trim() || '',
      dead_line_due_date: params.get('dead_line_due_date')?.trim() || '',
      millesime: params.get('millesime')?.trim() || null,
      availability_id: params.get('availability_id')?.trim() || '',
      newAtnr: null,
      newOrno: null,
      clientMail: null,
      dealerMail: null,
      initialATNR: null,
      initialORNO: null,
      dealerslist: (params.get('dealerslist') || '').split(','),
      userMail: params.get('usermail')?.trim() || null,
    };
    return object;
  };

  const login = () => {
    setLoading(true);
    /*
    instance.loginPopup(loginRequest).then((result) => {
      console.log('2 - login success: ', result);
      setLoading(false);
      const token = result.accessToken;

      if (result.account) {
        const username = result.account.name || '';
        const name = username.substring(0, username.indexOf("@") > -1 ? username.indexOf("@") : username.length);
        const firstname = name.substring(0, name.indexOf(".") > -1 ? name.indexOf(".") : name.length);
        const lastname = name.split('.').pop();
        let displayName = firstname + ' ' + lastname;
        if (result.uniqueId == 'edb96ca5-6f18-4434-bebb-0338c9033134') { // oubliez moi
          displayName = 'Nicolas Cissa';
        }
        const user: User = {
          id: result.uniqueId,
          username: result.account?.username || '',
          name: result.account?.name || '',
          completeName: displayName
        }
        setUser(user);
      }

      setToken(token);
      setConfigIds(getUrlParams);
      navigate('/configurator');
    }, (error) => {
      console.log('3 - login error: ', error);
      setLoading(false);
      navigate('/login');
    });
    */
    setConfigIds(getUrlParams);
    setLoading(false);
    navigate('/login');
  }

  useEffect(() => {
    login();
  }, []);

  return (
    <div className="authent-redirection">
    </div>
  );
}

export default LoginPage;