import { FunctionComponent } from "react";
import { Button, ButtonType } from "_components";
import './configurator-weight-modal-content-caravan.scss';
import { useTranslation } from 'react-i18next';


type ConfiguratorWeightModalContentProps = {
    onClose: () => void;
}

const ConfiguratorWeightModalContentCaravan: FunctionComponent<ConfiguratorWeightModalContentProps> = ({ onClose }) => {


    const { t } = useTranslation();

    const handleButtonWithdrawChoice = () => {
        onClose();
    };
    return (
        <div className="configurator-weight-modal-caravan-content">
            <div className="configurator-weight-modal-caravan-content__left-section">
                <div className="configurator-weight-modal-caravan-content__left-section-container">
                    <div className="configurator-weight-modal-caravan-content__left-section-header">
                        <div className="configurator-weight-modal-caravan-content__left-section-icon"></div>
                        <div className="configurator-weight-modal-caravan-content__left-section-warning">{t('overweight_popin_warning')}</div>
                    </div>
                    <div className="configurator-weight-modal-caravan-content__left-section-title">
                        {t('overweight_popin_overweight')}
                    </div>
                    <div className="configurator-weight-modal-caravan-content__left-section-description">
                        {t('overweight_popin_text_info_overweight_caravan')}
                        <div className="configurator-weight-modal-content__left-section-description-space">
                            {t('overweight_popin_text_info_overweight_caravan_2')}
                        </div>
                    </div>
                    <div className="configurator-weight-modal-caravan-content__button-container">
                        <Button type={ButtonType.PRIMARYSMALL} text={t('overweight_popin_remove_option_and_close')} onClick={handleButtonWithdrawChoice} />
                    </div>
                </div>
            </div>
        </div>
    );
}



export default ConfiguratorWeightModalContentCaravan;