import { atom } from 'recoil';
import customStorage from './customStorage';
import { ConfiguratorResponseType } from '_api';

const key = 'debugConfig';

const atomDebugConfig = atom<ConfiguratorResponseType | null>({
  key,
  default: null,
  effects: customStorage(key),
});

export default atomDebugConfig;
