
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { atomToken } from '_atoms';
import { LoginPage, NotFoundPage, ConnectedPage, ConfiguratorPage, OptionPage, AuthentRedirection, QuotesPage } from '_pages';
import { GlobalLoader, Toast, DebugTool } from '_components';
import { setApiLanguage, setAuthorizationToken } from '_api';
import { LoadingProvider, ModalProvider } from '_hooks';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

function App() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [token] = useRecoilState(atomToken);
  const resetToken = useResetRecoilState(atomToken);
  const [tokenLoaded, setTokenLoaded] = useState(false);

  useEffect(() => {
    setAuthorizationToken(token, () => {
      // On token expire.
      resetToken();
      navigate('/login');
      setTimeout(() => {
        toast.dismiss();
      });
    });
    setTokenLoaded(true);
  }, [token]);

  useEffect(() => {
    // const language = i18n.language;
    const language = i18n.resolvedLanguage;
    document.documentElement.setAttribute('lang', language);
    setApiLanguage(language);
  }, []);

  i18n.on('languageChanged', () => {
    const language = i18n.language;
    document.documentElement.setAttribute('lang', language);
    setApiLanguage(language);
  });


  return (
    <ModalProvider>
      <Toast />
      {process.env.REACT_APP_DEBUG_TOOL == "true" ? <DebugTool /> : null}
      <LoadingProvider>
        <GlobalLoader />
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path='/authent-redirection' element={<AuthentRedirection />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/' element={<ConnectedPage />}>
            {tokenLoaded ? (
              <>
                <Route path='configurator' element={<ConfiguratorPage />} />
                <Route path='quotepage' element={<QuotesPage />} />
                <Route path='optionpage' element={<OptionPage />} />
                <Route path="/" element={<Navigate to="/authent-redirection" />} />
              </>
            ) : null}
          </Route>
        </Routes>
      </LoadingProvider>
    </ModalProvider>
  );
}

export default App;
