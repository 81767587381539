import { FunctionComponent, useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { TextInput, CustomSelect, SelectType, TextArea, Button, ButtonType } from "_components";
import { useTranslation } from "react-i18next";
import './configurator-client-modal-content.scss';
import { ConfiguratorForm, DealerListType, InfoClient } from "_api";
import { useNavigate } from "react-router-dom";

export type ClientInformationsType = {
  client: {
    email?: string;
    firstname?: string;
    name?: string;
    phone?: string;
  }
}

type ConfiguratorClientModalContentProps = {
  onCreateQuoteClick: () => void,
  form: ConfiguratorForm;
  name: string;
  title: string;
  onClientChange: (client: InfoClient) => void,
  onSubmit?: () => void,
  onClose: () => void,
};

const ConfiguratorClientModalContent: FunctionComponent<ConfiguratorClientModalContentProps> = ({ name, title, onClose, onCreateQuoteClick,onClientChange }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [values, setValues] = useState({
      name:  '',
      firstname:  '',
      email: '',
      phone: '',
    });
    
  
    const handleChange = (key: string, value: string) => {
      setValues((prev) => {
        return {
          ...prev,
          [key]: value
        }
      });
    };

  const handleCreateQuoteButtonClick = () => {
    onCreateQuoteClick();
    onClose();
   // navigate('/quotepage');
  };
  
    const handleSubmit = (event: any) => {
  
      onClientChange({
        lastName: values.name || '',
        firstName: values.firstname || '',
        clientEmail: values.email || '',
        clientPhone: values.phone || '',
      })
      event.preventDefault();
      onClose();
      console.log(values);
    };
    return (
        <div className="modal-client-info">
          <div className="modal-client-info__title">{title}</div>
          <form className="modal-client-info__form">
            <TextInput id="clie ntName" type="text" name={`${name}.client.name`} placeholder={t('profile_lastname') + " *"} value={values.name} onChange={(e: any) => handleChange('name', e.target.value)} label={""} />
            <TextInput id="clientFirstname" type="text" name={`${name}.client.firstname`} placeholder={t('profile_firstname')} value={values.firstname} onChange={(e: any) => handleChange('firstname', e.target.value)} label={""} />
            <TextInput id="clientEmail" type="email" name={`${name}.client.email`} placeholder={t('profile_email') + " *"} value={values.email} onChange={(e: any) => handleChange('email', e.target.value)} label={""} />
            <TextInput id="clientPhone" type="text" name={`${name}.client.phone`} placeholder={t('profile_phone')} value={values.phone} onChange={(e: any) => handleChange('phone', e.target.value)} label={""} />
         
          </form>
          <div className="modal-client-info__buttons">

          <div className="modal-client-info__save-btn">
            <Button type={ButtonType.DARKSMALL} onClick={handleSubmit} disabled={!values.name || !values.email}  text={t('checking_configuration')} />
            </div>
            <div className="modal-client-info__cancel-btn">
            <Button type={ButtonType.DARKSMALL}  onClick={handleCreateQuoteButtonClick} text={t('configuration_skip_step')} />
          </div>
          </div>
         </div>
      );
    };
    
export default ConfiguratorClientModalContent;