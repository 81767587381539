import { useEffect } from 'react';
import { useSpring } from '@react-spring/web';

const animation = {
  from: { opacity: 0, transform: "translateY(-20px) scale(0.95)" },
  to: { opacity: 1, transform: "translateY(0) scale(1)" },
  config: { tension: 80, friction: 11 }
};

export function useEntrance(deps?: Array<unknown>) {
  const [entrance, setEntrance] = useSpring(() => animation);

  useEffect(() => {
    if (typeof deps !== undefined) {
      setEntrance(animation);
    }
  }, [deps, setEntrance]);

  return entrance;
}
