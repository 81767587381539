import { FunctionComponent, useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { AdditionalOptionItem, AdditionalOption } from "_components";

type AdditionalOptionsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
};

const AdditionalOptions: FunctionComponent<AdditionalOptionsProps> = ({ control, name }) => {
  const [newOption, setNewOption] = useState<AdditionalOption>({
    title: "",
    description: "",
    weight: null,
    price: null
  });

  const resetNewOption = () => {
    //setNewOption({ title: "", description: "", weight: 0, price: 0 });
    setNewOption({ title: "", description: "", weight: null, price: null });
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {

        //const [options, setOptions] = useState(field.value);

        /*
        useEffect(() => {
          field.onChange(options);
        }, [options]);
        */

        const handleAddOption = (option: AdditionalOption) => {
          const newOptions = [...field.value, option];
          field.onChange(newOptions);
          //const newOptions = [...options, option];
          //setOptions(newOptions);
          resetNewOption();
        };

        const handleUpdateOption = (updatedOption: AdditionalOption) => {
          const updatedOptions = field.value.map((option: AdditionalOption) =>
            option.id === updatedOption.id ? updatedOption : option
          );
          field.onChange(updatedOptions);
          /*
          const updatedOptions = options.map((option: AdditionalOption) =>
            option.id === updatedOption.id ? updatedOption : option
          );
          */
          //setOptions(updatedOptions);
        };

        const handleDeleteOption = (optionToDelete: AdditionalOption) => {
          const updatedOptions = field.value.filter((option: AdditionalOption) => option.id !== optionToDelete.id);
          field.onChange(updatedOptions);
          //const updatedOptions = options.filter((option: AdditionalOption) => option.id !== optionToDelete.id);
          //setOptions(updatedOptions);
        };

        return (
          <>
            {field.value.map((value: AdditionalOption) => (
              <AdditionalOptionItem
                option={value}
                onDelete={handleDeleteOption}
                onUpdate={handleUpdateOption}
                isUpdateMode={false}
                key={value.id}
              />
            ))}
            <AdditionalOptionItem
              option={newOption}
              onDelete={() => { null }}
              onUpdate={(option: AdditionalOption) => handleAddOption(option)}
              isUpdateMode={true}
              isNewOption={true}
            />
          </>
        );
      }}
    />
  );
};

export default AdditionalOptions;
