import { FunctionComponent, useEffect, useState } from "react";
import Button from "../Button/Button";
import { ButtonType } from "_components";
import './configurator-buttons.scss'
import { useTranslation } from "react-i18next";
import {  useNavigate } from 'react-router-dom';
import { ConfiguratorClientInfos, ConfiguratorForm } from "_api";


type ConfiguratorButtonsProps = {
  form: ConfiguratorForm;
  setClientInfoModal: () => void;
}

const ConfiguratorButtons: FunctionComponent<ConfiguratorButtonsProps> = ({setClientInfoModal, form}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

 const [isQuoteBtnActive, setIsQuoteBtnActive] = useState(false)
 const [isOrderBtnActive, setIsOrderBtnActive] = useState(false)

  useEffect(()=> {
    const validClient = Boolean((form.clientInfos as ConfiguratorClientInfos).client.name && (form.clientInfos as ConfiguratorClientInfos).client.email);
    const validDealer = Boolean((form.clientInfos as ConfiguratorClientInfos).dealer.name);
    setIsQuoteBtnActive(validClient);
    setIsOrderBtnActive(validDealer);
  }, [form])

 /* const handleCreateQuoteButtonClick = () => {
    isCreateQuoteClick('je suis le bouton quote');
    
    navigate('/quotepage');
  };*/

  return (
    <div className="configurator-buttons">
     {/*<Button type={ButtonType.GREYSMALL} text={t('configurator_create_quote')}  onClick={handleCreateQuoteButtonClick} disabled={false}/>*/}
      <Button type={ButtonType.LIGHTSMALL} text={t('configurator_order_action')} onClick={() => setClientInfoModal()}  disabled={!isOrderBtnActive}/>
    </div>
  );
}

export default ConfiguratorButtons;