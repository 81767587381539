
import { FunctionComponent ,useState} from "react";
import { Button, ButtonType, Radio, GreyCardSeats} from "_components";
import './configurator-weight-modal-content.scss';
import { yupResolver } from "@hookform/resolvers/yup";
import { Control, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ConfiguratorConfig, PTAC, PTACResponseType } from "_api/types/responses/configurator.response.type";

type ConfiguratorWeightModalContentProps = {
  onClose: () => void;
  control?: Control<TestFormValues>;
  weightOptions: PTACResponseType;
  gvw: number | null | undefined;
  ATID: string,
  onPTACChange: (id: string, ptac: string, ATID: string) => void;
  onCGChange: () => void;
  onRollback: () => void;
  configuration: ConfiguratorConfig;
}

type TestFormValues = {
  PTAC: string,
  id: string
}

const ConfiguratorWeightModalContent: FunctionComponent<ConfiguratorWeightModalContentProps> = ({ onClose, weightOptions, gvw, onPTACChange, ATID, onCGChange, onRollback, configuration }) => {

  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const testValidationSchema = Yup.object().shape({
    PTAC: Yup.string(),
  })
  const {
    control, handleSubmit, setValue,
  } = useForm<TestFormValues>({
    resolver: yupResolver(testValidationSchema),
    mode: 'onChange',
  });


  const handleChange = (e: any) => {
    
    setSelectedOption(e.target.value);
    setValue('id', e.target.id);
    setValue('PTAC', e.target.value);
  }; 

  const handleButtonPTAC = () => {
    if (selectedOption) {
      handleSubmit(onSubmit)(); 
    }
  }

  const onSubmit = (values: TestFormValues) => {

    onPTACChange(values.id, values.PTAC, ATID);
    onClose();
  }


  const handleButtonRemoveSeats = () => {
    onCGChange();
    onClose();
  };


  const handleButtonWithdrawChoice = () => {
    onRollback();
    onClose();
  };


  return (
    <div className="configurator-weight-modal-content">
      <div className="configurator-weight-modal-content__left-section">
        <div className="configurator-weight-modal-content__left-section-header">
          <div className="configurator-weight-modal-content__left-section-icon"></div>
          <div className="configurator-weight-modal-content__left-section-warning">{t('overweight_popin_warning')}</div>
        </div>
        <div className="configurator-weight-modal-content__left-section-text">
          {t('overweight_popin_overweight')}
        </div>
        <div className="configurator-weight-modal-content__left-section-description">
          {t('overweight_popin_text_info_overweight')}
          <div className="configurator-weight-modal-content__left-section-description-space">{t('overweight_popin_text_info_overweight_2')}</div>
          <div className="configurator-weight-modal-content__left-section-description-space">{t('overweight_popin_text_info_overweight_3')}</div>
        </div>
      </div>
      <div className="configurator-weight-modal-content__right-section">
        <div className="configurator-weight-modal-content__right-section-text">
          {t('overweight_popin_card_text_title')}
        </div>
        <div className="configurator-weight-modal-content__right-section-cards-container">
          <div className="configurator-weight-modal-content__right-section-cards-container-card">
            <div className="configurator-weight-modal-content__right-section-cards-container-card-title-choice1">
              {t('overweight_popin_title_choice')} 1
            </div>
            <div className="configurator-weight-modal-content__right-section-cards-container-card-title1">
              {t('overweight_popin_card_title_1')}
            </div>
            <div className="configurator-weight-modal-content__right-section-cards-container-card-body" >
              {t('overweight_popin_card_body_title_1')}
            </div>
            <div className="configurator-weight-modal-content__right-section-cards-container-card-greyCard">
              <GreyCardSeats largeVersion={true} value={configuration.seats_number-1} total={configuration.seats_number} />
            </div>
            <div className="configurator-weight-modal-content__right-section-cards-container-card-places">
              {configuration.seats_number} {t('overweight_places')}  <span className="configurator-weight-modal-content__right-section-cards-container-card-separator"></span>{configuration.seats_number - 1} {t('overweight_places')}
            </div>
            <div className="configurator-weight-modal-content__right-section-cards-container-card-button">
              <Button type={ButtonType.PRIMARYSMALL} text={t('overweight_popin_choose')} onClick={handleButtonRemoveSeats} />
            </div>
          </div>
          {weightOptions.Masse && weightOptions.Masse.length ? (
            <form className="configurator-weight-modal-content__right-section-cards-container-card">
              <div className="configurator-weight-modal-content__right-section-cards-container-card-title-choice2">
                {t('overweight_popin_title_choice')} 2
              </div>
              <div className="configurator-weight-modal-content__right-section-cards-container-card-title2">
                {t('overweight_popin_card_title_2')}
              </div>
              <div className="configurator-weight-modal-content__right-section-cards-container-card-body" >
                {t('overweight_popin_card_body_title_2')} <span className="configurator-weight-modal-content__right-section-cards-container-card-PTAC">{gvw} {t('weight_measure_unit')}</span> {t('overweight_popin_card_body_title_3')}
              </div>
              <div className="configurator-weight-modal-content__right-section-cards-container-card-choice">
                <Radio
                  options={weightOptions.Masse.map((_masse: PTAC) => {
                    const option = {
                      id: _masse.id,
                      values: _masse.values,
                      price: _masse.price,
                      label: _masse.label,
                      isSelected: _masse.isSelected,
                    }
                    return option;
                  })}
                  name="PTAC"
                  control={control}
                  label="" price={0}
                  onChange={handleChange}
                />
              </div>
              <div className="configurator-weight-modal-content__right-section-cards-container-card-button">
              <Button
              type={ButtonType.PRIMARYSMALL}
              text={t('overweight_popin_choose')}
              onClick={handleButtonPTAC} 
              disabled={!selectedOption}
            />
              </div>
            </form>
          ) : null}

        </div>
        <div className="configurator-weight-modal-content__button-container">
          <Button type={ButtonType.SECONDARY} text={t('overweight_popin_remove_option_and_close')} onClick={handleButtonWithdrawChoice} />
        </div>
      </div>
    </div>
  );
}

export default ConfiguratorWeightModalContent;