import { useState, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TextArea } from "_components";
import '../AdditionalOptionItem/additional-option-item.scss';

type CommentaryFormOptionType = {
  description: string
};

type CommentaryFormOptionProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
};

const CommentaryFormOption: FunctionComponent<CommentaryFormOptionProps> = ({ control, name }) => {

  const [updateMode, setUpdateMode] = useState(true);
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const [option, setOption] = useState<CommentaryFormOptionType>({
          description: field.value.description
        });

        return (
          <div className="additional-option">
            <div className="additional-option__title">{t('commentary')}</div>
            <div className="additional-option-item additional-option__item__startup-cost">
              <TextArea 
                id="commentary" 
                name="commentary" 
                label={t('commentary')}  
                value={option.description}
                placeholder={t('add_commentary')}
                disabled={!updateMode} 
                onChange={(e) => setOption({ ...option, description: (e.target as HTMLTextAreaElement).value })}
              />

              {updateMode ? (
                <div className="save-btn-container">
                  <button 
                    className="save-btn"
                    onClick={()=> {field.onChange(option); setUpdateMode(false);}} 
                    disabled={!option.description.length }>
                    {t('validate')}
                  </button>
                </div>
              ) : (
                <button className="action-button--update-btn" onClick={() => setUpdateMode(true)}></button>
              )}
            </div>
          </div>
        );
      }}
    />
  )
};

export default CommentaryFormOption;