import { FunctionComponent, memo, useCallback, useState } from "react";
import './quote-print.scss';
import { Button, ButtonType, TextInput } from "_components";
import { useTranslation } from 'react-i18next';



type QuotePrintProps = {
  setQuoteData: (data: { periodOfValidity: string, quoteReference: string }) => void;
}

const QuotePrint: FunctionComponent<QuotePrintProps> = (props: QuotePrintProps) => {
  const { t } = useTranslation();
  const [quoteData, setQuoteData] = useState({ periodOfValidity: '30', quoteReference: '' });

const handleSaveQuote = (event: React.MouseEvent<HTMLElement>) => {
  event.preventDefault();
  props.setQuoteData(quoteData);
};

  const isInputEmpty = useCallback(() => {
    const isPeriodOfValidityEmpty = quoteData.periodOfValidity.trim() === '';
    const isQuoteReferenceValueEmpty = quoteData.quoteReference.trim() === '';

    return isPeriodOfValidityEmpty || isQuoteReferenceValueEmpty;
  }, [quoteData]);

  

  const redirectToQuotePage = () => {
    window.open(process.env.REACT_APP_URL + "/quotation")
  }

  return (
    <>
      <div className="quote-print">
        <div className="quote-print__description">
          {t('quote_print_description')}<br /><span onClick={redirectToQuotePage} className="quote-print__quote">{t('quote_print_quote')}</span>
        </div>
        <div className="save-quote-modal-content__form">
          <div className="quote-print__button-reference-content">
            <div className="quote-print__text-reference">{t('quote_print_text_reference')}</div>
            <TextInput id="quote-print__button-validity" type="text" name="name" label="" placeholder={t('quote_print_text_reference')}
                 onChange={(event) => setQuoteData({ ...quoteData, quoteReference: event.currentTarget.value })}
            />
          </div>
          <div className="quote-print__button-validity-content">
            <div className="quote-print__text-validity">{t('quote_print_text_validity')}</div>
            <TextInput id="quote-print__button-validity" type="number" name="name" label="" placeholder={t('quote_print_button_validity')}
                value={quoteData.periodOfValidity} onChange={(event) => setQuoteData({ ...quoteData, periodOfValidity: event.currentTarget.value })}
            />
          </div>
          <div className="quote-print__button-save">
            <Button type={ButtonType.PRIMARY} text={t("quote_print_save")} onClick={handleSaveQuote} disabled={isInputEmpty()} />
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(QuotePrint);