import { FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import { formatPrice } from "_utils";
import './vertical-value-switch.scss';

export type VerticalValueSwitchType = {
  _id?: number,
  id: string,
  title: string,
  price: number,
  description: string,
  value: string,
  selected: boolean,
  subLabel: string,
  disabled: boolean
}

export type VerticalValueSwitchProps = {
  // shared.
  title: string,
  name: string;
  price: number,
  description: string;
  // native.
  value?: boolean;
  disabled: boolean;
  onChange?(value: boolean): (value: boolean) => void;
  // react hook form.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
}

const VerticalValueSwitch: FunctionComponent<VerticalValueSwitchProps> = (props) => {

  const priceValue = props.price ? `(+ ${formatPrice(props.price)})` : `(${formatPrice(0)})`;

  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => {
          return (
            <button className={`vertical-value-switch ${field.value ? ' vertical-value-switch--active' : ''}`} onClick={(e) => {
              e.preventDefault();
              if (props.disabled !== true) {
                field.onChange(!field.value)
              }
            }}>
              <i className="vertical-value-switch__icon"></i>
              <div className="vertical-value-switch__text">
                <div className="vertical-value-switch__title">{props.title} <span className="vertical-value-switch__price">{priceValue}</span></div>
                <div className="vertical-value-switch__description" dangerouslySetInnerHTML={{ __html: props.description }}></div>
              </div>
            </button>
          );
        }} />
    );
  }
  else {
    return null;
    return (
      <button className={`vertical-value-switch ${props.value ? ' vertical-value-switch--active' : ''}`} onClick={() => props.onChange ? props.onChange(!props.value) : null}>
        <i className="vertical-value-switch__icon"></i>
        <div className="vertical-value-switch__text">
          <div className="vertical-value-switch__title">{props.title} <span className="vertical-value-switch__price">{priceValue}</span></div>
          <div className="vertical-value-switch__description">{props.description} </div>
        </div>
      </button>
    )
  }

}

export default VerticalValueSwitch;